import * as React from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Card, CardContent, TextField} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useSnackbar, SnackbarProvider } from 'notistack'
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  SwipeableDrawer
} from "@mui/material";
import {
  ArrowDropDown,
  BusinessOutlined,
  PersonAddAlt1Rounded,
  PowerSettingsNew,
  SettingsRounded,
  Share,
  SmsFailedRounded,
  SyncAlt,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { useCookies } from "react-cookie";
import NoteIcon from "@mui/icons-material/Note";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CategoryIcon from "@mui/icons-material/Category";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import DOMPurify from "dompurify";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const drawerWidth = 260;
// import { LockRounded, CloseRounded } from '@mui/icons-material';



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#000",
  color: "white",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#fff",
  color: "white",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#000",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


// React.useEffect(()=>{
//   getSidebarItems()
// },[]);






// const sidebar_Items1111 = [
//   auth && auth.role_name.dashboard  == true &&
//   {
//     name: "Dashboard",
//     icon: <SpaceDashboardIcon className="sidebarIcons" style={{}} />,
//     link: "/dashboard",
//   },

//   auth && auth.role_name.addcate  == true &&
//   {
//     name: "Categories",
//     icon: <CategoryIcon className="sidebarIcons" style={{}} />,
//     link: "/category",
//   },
//   // {
//   //   name: "Sub Categories",
//   //   icon: <CategoryIcon className="sidebarIcons" style={{}} />,
//   //   link: "/subcategory",
//   // },

//   {
//     name: " Role Management",
//     icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
//     link: "/role",
//   },
//   auth && auth.role_name.addcustomer  == true &&
//   {
//     name: "Customer Management",
//     icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
//     link: "/customer",
//   },

//   auth && auth.role_name.addservice  == true &&
//   {
//     name: "Services",
//     icon: <BusinessOutlined className="sidebarIcons" style={{}} />,
//     link: "/service",
//   },

//   auth && auth.role_name.addbooking  == true &&
//   {
//     name: "Bookings",
//     icon: <NoteIcon className="sidebarIcons" style={{}} />,
//     link: "/booking",
//   },

//   auth && auth.role_name.addtalent  == true &&
//   {
//     name: "Talent",
//     icon: <PermContactCalendarIcon className="sidebarIcons" style={{}} />,
//     link: "/talent",
//   },
//   auth && auth.role_name.addmodel  == true &&
//   {
//     name: "Models",
//     icon: <AssignmentLateIcon className="sidebarIcons" style={{}} />,
//     link: "/models",
//   },

//   auth && auth.role_name.addstudio  == true &&

//   {
//     name: "Studios",
//     icon: <AutoAwesomeMosaicIcon className="sidebarIcons" style={{}} />,
//     link: "/studio",
//   },

//   {
//   	name: 'Settings',
//   	icon: <SettingsRounded className='sidebarIcons' style={{}} />,
//   	link: '/setting',
//     submenu :[
//       {
//         name: 'talent payment',
//         link: '/setting',
//         submenuOrder:1
//       }
//     ]
//   },

//   auth && auth.role_name.chat  == true &&
//   {
//     name: "Chat",
//     icon: <SmsFailedRounded className="sidebarIcons" style={{}} />,
//     link: "/chat",
//   },


//   auth && auth.role_name.addusers  == true &&
//   {
//     name: "User Management",
//     icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
//     link: "/user",
//   },
// ];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: "15px",
  },
}));
// const BootstrapDialogTitle = (props) => {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 16,
//             top: 15,
//             color: (theme) => theme.palette.grey[500],
//             border: "1px solid #E5EBF0",
//             borderRadius: "8px",
//             height: 38,
//             width: 38,
//           }}
//         >
//           <CloseIcon className="fz-18" />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// };

export default function MiniDrawer({ type,submenuOrder }) {
  let admindetails = JSON.parse(localStorage.getItem("userData"));
  let getfcmtoken = localStorage.getItem("fcmkey")
  const [firstLetter] = admindetails && admindetails.name.toUpperCase();
  const  dp = admindetails && admindetails.profile;
  const email = admindetails && admindetails.email;
  const [cookies, setCookie, removeCookie] = useCookies(["value"]);
  const [open, setOpen] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  let navigate = useNavigate();
  // notification dropdown
  const [openNotifiction, setOpenNotifiction] = React.useState(false);
  const [notificationData, setNotificationData] = React.useState("");
  // console.log(notificationData,"notificationDatanotificationData")
  const [notificationLength, setNotificationLength] = React.useState("");
  const { enqueueSnackbar } = useSnackbar()
  const [serviceDetailes, setServiceDetailes] = React.useState("");
  const [openview, setOpenview] = React.useState(false);
  const [alldata, setAlldata] = React.useState("");
  const [opens, setOpens] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);


  const auth = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : "";

  const getSidebarItems = () => {
    var sidebar_Items = []
    if (auth.role === 'Admin') {
      sidebar_Items.push({
        name: "Dashboard",
        icon: <SpaceDashboardIcon className="sidebarIcons" style={{}} />,
        link: "/dashboard",
      });
      sidebar_Items.push({
        name: "Categories",
        icon: <CategoryIcon className="sidebarIcons" style={{}} />,
        link: "/category",
      });
      sidebar_Items.push({
        name: "Role Management",
        icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
        link: "/role",
      });
      sidebar_Items.push({
        name: "Customer Management",
        icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
        link: "/customer",
      });
      sidebar_Items.push({
        name: "Services",
        icon: <BusinessOutlined className="sidebarIcons" style={{}} />,
        link: "/service",
      });
      sidebar_Items.push({
        name: "Bookings",
        icon: <NoteIcon className="sidebarIcons" style={{}} />,
        link: "/booking",
      });
      sidebar_Items.push({
        name: "Talents",
        icon: <PermContactCalendarIcon className="sidebarIcons" style={{}} />,
        link: "/talent",
      });
      sidebar_Items.push({
        name: "Models",
        icon: <AssignmentLateIcon className="sidebarIcons" style={{}} />,
        link: "/models",
      });
      sidebar_Items.push({
        name: "Studios",
        icon: <AutoAwesomeMosaicIcon className="sidebarIcons" style={{}} />,
        link: "/studio",
      });
      sidebar_Items.push({
        name: 'Settings',
        icon: <SettingsRounded className='sidebarIcons' style={{}} />,
        link: '/setting',
        submenu :[
          {
            name: 'talent payment',
            link: '/setting',
            submenuOrder:1
          }
        ]
      });
      sidebar_Items.push({
        name: "Chat",
        icon: <SmsFailedRounded className="sidebarIcons" style={{}} />,
        link: "/chat",
      });
      sidebar_Items.push({
        name: "Admin Users",
        icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
        link: "/user",
      });
    } 
    else if(auth) {
        if(auth.role_name.dashboard  === true) {
          sidebar_Items.push({
            name: "Dashboard",
            icon: <SpaceDashboardIcon className="sidebarIcons" style={{}} />,
            link: "/dashboard",
          })
        } 
        if(auth.role_name.addcate  === true ) {
          sidebar_Items.push({
            name: "Categories",
            icon: <CategoryIcon className="sidebarIcons" style={{}} />,
            link: "/category",
          })
        }
        if(auth.role_name.roleManagement  === true ) {
        sidebar_Items.push({
          name: " Role Management",
          icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
          link: "/role",
        })
      }
        if(auth.role_name.addcustomer  === true ) {
          sidebar_Items.push({
            name: "Customer Management",
            icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
            link: "/customer",
          })
        }
        if(auth.role_name.addservice  === true ) {
          sidebar_Items.push({
            name: "Services",
            icon: <BusinessOutlined className="sidebarIcons" style={{}} />,
            link: "/service",
          })
        }
        if(auth.role_name.addbooking  === true ) {
          sidebar_Items.push({
            name: "Bookings",
            icon: <NoteIcon className="sidebarIcons" style={{}} />,
            link: "/booking",
          })
        }
        if(auth.role_name.addtalent  === true ) {
          sidebar_Items.push({
            name: "Talents",
            icon: <PermContactCalendarIcon className="sidebarIcons" style={{}} />,
            link: "/talent",
          })
        }
        if(auth.role_name.addmodel  === true ) {
          sidebar_Items.push({
            name: "Models",
            icon: <AssignmentLateIcon className="sidebarIcons" style={{}} />,
            link: "/models",
          })
        }
        if(auth.role_name.addstudio  === true ) {
          sidebar_Items.push({
            name: "Studios",
            icon: <AutoAwesomeMosaicIcon className="sidebarIcons" style={{}} />,
            link: "/studio",
          })
        }
    
        sidebar_Items.push({
          name: 'Settings',
          icon: <SettingsRounded className='sidebarIcons' style={{}} />,
          link: '/setting',
          submenu :[
            {
              name: 'talent payment',
              link: '/setting',
              submenuOrder:1
            }
          ]
        })
        if(auth.role_name.chat  === true ) {
          sidebar_Items.push({
            name: "Chat",
            icon: <SmsFailedRounded className="sidebarIcons" style={{}} />,
            link: "/chat",
          })
        }
        if(auth.role_name.addusers  === true ) {
          sidebar_Items.push({
            name: "Admin Users",
            icon: <ManageAccountsIcon className="sidebarIcons" style={{}} />,
            link: "/user",
          })
        }
      }
      return sidebar_Items
    }

  const handleOpen = () => {
    setOpens(true);
  };



  // const handleShowChangePassword = () => {
  //   setShowChangePassword(true);
  // };

  const habdlclose = () => {
    setOpens(false);
  };

  // const handleChangePassword = () => {
  //   console.log('Change password logic here');
  // };

  // const handleProfilePicChange = () => {
  //   console.log('Change profile picture logic here');
  // };
  //   console.log(notificationData,"=> => => => => => => => => => ");

  // const handleDrawerToggle = () => {
  //   setOpen(!open);
  //   document.querySelector("body").classList.toggle("sidebarclose");
  // };

  // const PageNavigation = (url) => {
  //   // window.history.pushState("", "", url);
  //   navigate(url);
  //   // document.querySelector('body').classList.remove("sidebarclose");
  // };


  // ----responsive drawer------
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ paddingTop: 60 }}
     >
      <List>
        {getSidebarItems().map((text, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemButton
              sx={{
                minHeight: 44,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                fontSize: 13,
                paddingLeft: 4,
              }}
              selected={selectedIndex === index}
            // selected={type == text.name}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "white",
                  marginRight: 2,
                }}
              >
                {text.icon}
              </ListItemIcon>
              <ListItemText
                primary={text.name}
                sx={{ opacity: open ? 1 : 0 }}
                onClick={() => navigate(text.link)}
                className="sidebar_itemText"
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );



  const getNotification = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `followandlike/getthenotify`
      );
      const sortedNotifications = response.data.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const unreadNotifications = sortedNotifications.filter(
        (notif) => !notif.opened !== false
      );
      setNotificationLength(unreadNotifications);
      setNotificationData(sortedNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

    // notification section
    React.useEffect(() => {
      getNotification();
    }, []);

//  const data1 = notificationData.map((item)=>{
//     const createdAt = moment(item.createdAt);
//     const now = moment();
//     const timeDifference = now.diff(createdAt, 'days', true); 

//     let formattedDate;
//     if (timeDifference < 1) {
      
//       formattedDate = createdAt.fromNow();
//     } else if (timeDifference < 7) {
//       formattedDate = createdAt.startOf('day').format('dddd, MMMM Do, YYYY'); 
//     } else {
      
//       const roundedWeeks = Math.round(timeDifference / 7);
//       formattedDate = `${roundedWeeks} week${roundedWeeks > 1 ? 's' : ''} ago`;
//     }

//   })


  //   const handleDelete = async(data) =>{
  // alert(data)
  //   }

  const getDetailes = async (notifyId) => {
    // const notifyId = data._id;
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
        `followandlike/getServicenotification/${notifyId}`
      )
      .then((res) => {
        console.log(res.data.reminders, "responce");
        let detailes = res.data.reminders?.Service_id;
        setServiceDetailes(detailes);
        handleViewOpen(detailes);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleView = (data) => {
    console.log(data)
    getDetailes(data._id);
  };

  const handleViewOpen = (detailes) => {
    console.log(detailes,'detailesdetailes');
    setAlldata(detailes);
    setOpenview(true);
    setOpenNotifiction(false);
  };

  const handleClickClose = () => {
    setOpenview(false);
    setOpenNotifiction(false);
    setAlldata("");
    getNotification();
  };

  // ----account dropdown-----

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAcct = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClose = () => {
    setOpenNotifiction(false);
  };
  const handleProfileClick = () => {
   navigate('/adminprofile')

  };

  const handleLogoutClick = async () => {

    setAnchorEl(null);
    let body = {
      email: email,
      fcmkey: getfcmtoken,
    }
    console.log(body, 'sssssdtaaaaaaaaa');
    await axios.post(process.env.REACT_APP_BASE_URL + `adminpart/logout`, body)
      .then((res) => {
        removeCookie("value");
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        navigate("/");
        firebase.database().ref(`Users/${admindetails._id}`).update({
          isOnline: false,
          fcmkey: "",
        });
        navigate("/");
        enqueueSnackbar("Logout Successful", { variant: 'success' })
      })
      .catch((error) => {
        // navigate("/");
        
        enqueueSnackbar(error.message === 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
        console.log(error, "error");
      })

  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (event, index) => {
    console.log(index,'index after selecting')
    setSelectedIndex(index);
  };

  React.useState(() => {
    setSelectedIndex(type);
  }, [type]);


  const showSettingsMenu = () => {
    document.querySelector(".settings").classList.toggle("showSettingMenu");
  };
  const showAccountMenu = () => {
    document.querySelector("body").classList.toggle("showAccountMenu");
  };


  const handleDelete = async (ndataToDelete) => {
    try {
        // Make a PUT request to update isdeletepartial to true
        await axios.put(process.env.REACT_APP_BASE_URL + `followandlike/PdeleteNotifications/${ndataToDelete._id}`, {
          isdeletepartial: true
        });

        // Filter out the deleted item from the state
        const updatedNotifications = notificationData.filter((ndata) =>  
            ndata._id !== ndataToDelete._id
        );

        // Update state
        setNotificationData(updatedNotifications);
    } catch (error) {
        console.error("Error updating notification:", error);
    }
};

//   const handleDelete = (ndataToDelete) => {
//     // Filter out the object with the matching _id from the original array
//     const updatedNotifications = notificationData.filter((ndata) =>  
//         ndata._id !== ndataToDelete._id
//     ); 
//     // Update state with the filtered array
//     setNotificationData(updatedNotifications);
// };
  
  const handleNotificationClick = (event) => {
    setOpenNotifiction(true);
  };
  const description = alldata && alldata.Description ? alldata.Description : '';
  const sanitizedContent = DOMPurify.sanitize(description || '', {
    ALLOWED_TAGS: ["img"],
  });
  // const [fileTypes, setFileTypes] = React.useState([]);
  // React.useEffect(() => {
  //   if (alldata != "") {
  //     const fetchFileTypes = async () => {
  //       const types = await Promise.all(
  //         alldata.additionalimage &&
  //         alldata.additionalimage?.length > 0 &&
  //         alldata.additionalimage.map(async (fileUrl) => {
  //           const type = await getFileType(fileUrl);
  //           console.log(type, "typetype");
  //           return type;
  //         })
  //       );
  //       setFileTypes(types);
  //     };

  //     fetchFileTypes();
  //   }
  // }, [alldata]);

  // const getFileType = async (fileUrl) => {
  //   if (fileUrl.startsWith("blob:")) {
  //     try {
  //       const response = await axios.get(fileUrl, { responseType: "blob" });
  //       return response.data.type;
  //     } catch (error) {
  //       console.error("Error fetching the file:", error);
  //       return "video/mp4";
  //     }
  //   } else {
  //     let abc = inferMimeType(fileUrl);
  //     return abc;
  //   }
  // };

  // const inferMimeType = (fileUrl) => {
  //   const extension = fileUrl.split(".").pop();
  //   switch (extension) {
  //     case "jpg":
  //     case "jpeg":
  //     case "png":
  //     case "gif":
  //       return `image/${extension}`;
  //     case "mp4":
  //     case "webm":
  //     case "ogg":
  //       return `video/${extension}`;
  //     default:
  //       return null;
  //   }
  // };
  
  return (
    <Box sx={{ display: "flex", minHeight: 64 }}>
      <AppBar position="fixed" open={open} className="appbar">
        <Toolbar className="toolbar">
          <Box
            className="logo"
            style={{ marginLeft: "-15px" }}
          >
            <img
              src={require("./../assets/images/konnectk.png")}
              width="100%"
              height="50px"
              style={{objectFit:'contain'}}
              alt="Logo"
            />
          </Box>
          <Box className="account">
            <IconButton
              onClick={handleNotificationClick}
              size="small"
              sx={{ ml: 2 }}
              aria-label="show notifications"
              disableRipple={true}
            >
         <Stack spacing={5} direction="row">
  <Badge badgeContent={notificationLength.length} style={{ color: '#fff' }}>
    <NotificationsIcon />
  </Badge>
</Stack>
            </IconButton>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableRipple={true}
            >
              <Avatar 
              sx={{ width: 32, height: 32, marginRight: 1 }} 
              src={process.env.REACT_APP_BASE_URL + `${dp}`}
              >
                { firstLetter && firstLetter}
              </Avatar>
              <Typography
                component="div"
                variant="body1"
                className="fz-15 name"
                style={{ color: 'white' }} 
              >
                {`${admindetails.name ? admindetails.name : ''}${admindetails.name && admindetails.lastname ? ' ' : ''}${admindetails.lastname ? admindetails.lastname : ''}`} 
                {/* {admindetails.fullname} */}
              </Typography>
              <ArrowDropDown
                sx={{ marginTop: -0.4, fontSize: 18, color: "#fff" }}
              />
            </IconButton>


          </Box>
          <Menu
            className="acct-menu"
            anchorEl={anchorEl}
            id="account-menu"
            open={openAcct}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiSvgIcon-root": {
                  width: 16,
                  height: 16,
                  ml: -0.5,
                  mr: 1,
                  color: "#333333",
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem className='fz-14' onClick={handleProfileClick}>
              <PersonAddAlt1Rounded className='fz-16'/> My Profile
            </MenuItem>

            <MenuItem className="fz-14" onClick={handleLogoutClick}>
              <PowerSettingsNew className="fz-16" /> Logout
            </MenuItem>
          </Menu>

          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={() => showAccountMenu()}
            // sx={{ ...(open && { display: 'none' }) }}
            className="Res_AccountIcon"
          >
            <Share />
          </IconButton>
          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={() => showSettingsMenu()}
            // sx={{ ...(open && { display: 'none' }) }}
            className="Res_settingsIcon"
          >
            <SyncAlt />
          </IconButton>
          <Box className="res-menu-icon">
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={toggleDrawer(anchor, true)}
                  // sx={{ ...(open && { display: 'none' }) }}
                  className=""
                >
                  <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  className="res-drawer"
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open} className="Custom_drawer">
        {/* <Box className={open ? "menuicon-box" : "menuIcon-boxClosed"}>
          {open ? (
            <Typography component="h4" variant="body1" className="fz-12">
            </Typography>
          ) : null}
          <IconButton onClick={handleDrawerToggle}>
            <MenuOpen className="menuicon" />
          </IconButton> 
        </Box> */}
        <List>
          {getSidebarItems().map((text, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: "block" }}
              selected={selectedIndex === index}
            >
              <ListItemButton
                sx={{
                  minHeight: 44,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  fontSize: 13,
                  paddingLeft: 4,
                }}

                onClick={(event) => {
                  handleListItemClick(event, index);
                  navigate(text.link);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                    marginRight: 2,
                  }}
                  // onClick={(event) => {
                  //   handleListItemClick(event, index);
                  //   navigate(text.link);
                  // }}
                >
                  {text.icon}
                </ListItemIcon  >
                <ListItemText
                  primary={text.name}
                  sx={{ opacity: open ? 1 : 0 }}
                  // onClick={(event) => {
                  //   handleListItemClick(event, index);
                  //   navigate(text.link);
                  // }}
                  className="sidebar_itemText"
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <DrawerHeader />

      <Dialog
        open={openNotifiction}
        keepMounted
        onClose={handleNotificationClose}
        aria-describedby="alert-dialog-slide-description"
        className="custom_modal"
        PaperProps={{
          style: {
            width: "35%",
            margin: "auto",
            position: "absolute",
            right: 0,
            backgroundColor: "#e6e6e6",
          },
        }}
      >
        <DialogTitle >Notifications</DialogTitle>
        <DialogContent dividers>
          <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>
            <Box>
              {notificationData && notificationData.length > 0 ? (
                notificationData.map((ndata, i) => (
                  
                  <Card key={i} style={{ marginBottom: 8 }}>
                    <CardContent onClick={(e) => handleView(ndata)}>
                      {ndata.opened !== true && (
                        <FiberManualRecordIcon
                          style={{ color: "green", marginRight: 8 }}
                        />
                      )}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flexGrow: 1 }}>
                        <Typography
                        key={
                          ndata._id || Math.random().toString(36)}
                            component="div"
                            variant="body1"
                            className="fz-15 semibold"
                          >
                           {moment(ndata.createdAt).fromNow()}
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            className="fz-15 semibold"
                          >
                            Title: {ndata.title}
                          </Typography>
                          {/* <Typography
                            component="div"
                            variant="body1"
                            className="fz-15"
                          >
                            Message: {ndata.data.message}
                          </Typography> */}
                          <Typography
                            component="div"
                            variant="body1"
                            className="fz-15 semibold"
                          >
                            Content:
                          </Typography>
                          <Typography
                            component="div"
                            variant="body1"
                            className="fz-15"
                          >
                            {ndata.body}
                          </Typography>
                        </div>
                        <CloseIcon
                          style={{ marginLeft: 'auto', cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();  
                            handleDelete(ndata)
                          }
                           }
                        />
                      </div>
                    </CardContent>
                  </Card>
                  // ))}
                ))
              ) : (
                <Typography variant="body1">No Notifications</Typography>
              )}
            </Box>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleNotificationClose} className="cancel_btn" style={{ color: '#000' ,fontWeight:'900' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <BootstrapDialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={openview}
        className="custom_modal"
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{fontWeight:'bold'}}>
          Service Details
        </DialogTitle>

        <DialogContent dividers>
          <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>
            <Box className="">
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                Service:
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {alldata ? alldata.servicename : "-"}
              </Typography>
            </Box>
           

            {/* <Box className="">
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                Category:
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {alldata &&
                  alldata.Category_id?.length &&
                  alldata.Category_id.map((data) => data.title).join(
                    ", "
                  )}
              </Typography>
            </Box> */}

            <Box className="">
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                {" "}
                Price(AED):{" "}
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {" "}
                {alldata && alldata.pricing}{" "}
              </Typography>
            </Box>

            <Box className="">
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                Duration:{" "}
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {alldata && alldata.duration}
              </Typography>
            </Box>
            <Box className="">
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                Location:{" "}
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {alldata && alldata.location}
              </Typography>
            </Box>

            <Box className="">
              {alldata?.servicetype === "Seasonal" ? (
              <>
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                Start Date:{" "}
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {alldata && moment(alldata.startDate).format("DD-MM-YYYY")}
              </Typography>
              </>
              ): null}
            </Box>

            <Box className="">
            {alldata?.servicetype && alldata?.servicetype === "Seasonal" ? (
              <>
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                End Date:{" "}
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {alldata && moment(alldata.endDate).format("DD-MM-YYYY")}
              </Typography>
              </>
               ): null }
            </Box>

            <Box className="">
              <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
              >
                Service Type:{" "}
              </Typography>
              <Typography component="span" variant="body1" className="fz-15 ">
                {alldata && alldata.servicetype}
              </Typography>
            </Box>
          </Grid>

          <Grid
            container
            columnSpacing={2}
            rowSpacing={1}
            className=" mb-10 align-items-center"
          >
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="carosuel mb-10">
                <Typography
                  component="span"
                  variant="body1"
                  className="fz-15 semibold"
                >
                  Primary Image:{" "}
                </Typography>

                <Swiper
                  modules={[Navigation, Pagination]}
                  className="mySwiper "
                  slidesPerView={1}
                  pagination={{
                    clickable: true,
                  }}
                >
                  <SwiperSlide>
                    {" "}
                    <img
                      width={"100%"}
                      height={250}
                      src={
                        process.env.REACT_APP_BASE_URL +
                        `${alldata && alldata.primaryimage}`
                      }
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </Grid> */}

            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="carosuel mb-10">
                <Typography
                  component="span"
                  variant="body1"
                  className="fz-15 semibold"
                >
                  Additional Images:{" "}
                </Typography>

                <Swiper
                  modules={[Navigation, Pagination]}
                  className="mySwiper "
                  slidesPerView={1}
                  pagination={{
                    clickable: true,
                  }}
                >
                  {alldata &&
                    alldata.additionalimage &&
                    alldata.additionalimage?.length > 0 &&
                    alldata.additionalimage.map((tdata, i) => (
                      <>
                        <SwiperSlide>
                          {fileTypes[i]?.includes("image") ? (
                            <img
                              swidth={"100%"}
                              height={250}
                              src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                            />
                          ) : (
                            <video
                              controls
                              type={fileTypes[i]}
                              width={"100%"}
                              height={250}
                              src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                            />
                          )}
                        </SwiperSlide>
                      </>
                    ))}
                </Swiper>
              </div>
            </Grid> */}

            {alldata && alldata.addionalimage && alldata.addionalimage?.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="carosuel mb-10">
                  <Typography component="span" variant="body1" className="fz-15 semibold" >
                    Additional Image:{" "}</Typography>
                  <Swiper
                    modules={[Navigation, Pagination]}
                    className="mySwiper "
                    slidesPerView={1}
                    pagination={{
                      clickable: true,
                    }}
                  >
                    {alldata && alldata.addionalimage && alldata.addionalimage?.length > 0 && alldata.addionalimage.map((tdata, i) => {
                      //     console.log(tdata,"tdata");
                      //     return <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                      const fileExtension = tdata
                        .split(".")
                        .pop()
                        .toLowerCase();
                      console.log(
                        fileExtension,
                        "fileExtensionfileExtension"
                      );
                      if (fileExtension === "mp4") {
                        return (
                          <SwiperSlide key={i}>
                            <video
                              src={
                                process.env.REACT_APP_BASE_URL + `${tdata}`
                              }
                              type="video/mp4"
                              width="100%"
                              height={250}
                              controls
                            />
                          </SwiperSlide>
                        );
                      } else if (
                        ["jpg", "png", "jpeg"].includes(fileExtension)
                      ) {
                        return (
                          <SwiperSlide key={i}>
                            <img
                              width={"100%"}
                              height={250}
                              src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                              alt="AdditionalImg"
                            />
                          </SwiperSlide>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Swiper>
                </div>
              </Grid>
            )}

            {alldata &&
              alldata.addionalvideo &&
              alldata.addionalvideo?.length > 0 && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="carosuel mb-10">
                    <Typography
                      component="span"
                      variant="body1"
                      className="fz-15 semibold"
                    >
                      Additional Videos:{" "}
                    </Typography>

                    <Swiper
                      modules={[Navigation, Pagination]}
                      className="mySwiper "
                      slidesPerView={1}
                      pagination={{
                        clickable: true,
                      }}
                    >
                      {alldata &&
                        alldata.addionalvideo &&
                        alldata.addionalvideo?.length > 0 &&
                        alldata.addionalvideo.map((tdata, i) => (
                          <SwiperSlide>
                            {" "}
                            <video
                              controls
                              width={"100%"}
                              height={250}
                              src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </Grid>
              )}
          </Grid>

          <Typography
                component="span"
                variant="body1"
                className="fz-15 semibold"
          >
            Description:{" "}
          </Typography>
          {sanitizedContent ? (
            <div
              className="w-full editor_view"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
          ) : (
            <Typography className="p18_tag">There is no description</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button className="cancel_btn" onClick={handleClickClose} style={{ color: '#060a0f' , fontWeight:'bold'}}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <>
        <IconButton onClick={handleOpen}>
          <PersonAddAlt1Rounded />
        </IconButton>

    <Dialog
          open={opens}
          onClose={habdlclose}
          PaperProps={{
            style: {
              margin: '20px',
              width: '300px',
              position: 'absolute',
              top: '20px',
              right: '20px',
            },
          }}
        >
          <DialogTitle>
        
          
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                {admindetails.profile?.length > 0 ? (
                  <img
                    src={process.env.REACT_APP_BASE_URL + `${admindetails.profile}`}
                    height={100}
                    width={100}
                    style={{ borderRadius: '50%' }}
                    alt="adminImg"
                  />
                ) : (
                  <Avatar  sx={{ width: 100, height: 102, margin: 1 }}
                    alt={admindetails.name ? admindetails.name.charAt(0).toUpperCase() : 'Y'} accessibility
                  >
                    {admindetails.name ? admindetails.name.charAt(0).toUpperCase() : 'Y'}
                  </Avatar>
                )}
              </Grid>
              <Grid container justify="center" item>
                <Typography variant="body2" align="center" style={{textAlign:"center"}}>
                  {admindetails.name}
                </Typography>
                <Typography variant="body" align="center">
                  {admindetails.email}
                </Typography>
              </Grid>

              {showChangePassword && (
                <Grid item>
                  <TextField
                    type="password"
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    type="password"
                    label="Confirm Password"
                    variant="outlined"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    margin="normal"
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>

            {/* {showChangePassword ? <Button onClick={handleChangePassword} color="primary" >
              Change Password
            </Button> : ""} */}
            <Grid >
              {/* <Button onClick={handleShowChangePassword}>
                Change Password
              </Button> */}
            </Grid>
            <Button onClick={habdlclose} style={{ color: '#B4522D' }} >
             CLOSE
            </Button>
          </DialogActions>
        </Dialog> 
      </>
    </Box>
  );
}
