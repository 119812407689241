import { PlaylistAddRounded, SearchOutlined } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios, { all } from 'axios'
import DOMPurify from 'dompurify';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar, SnackbarProvider } from 'notistack'
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Select from '@mui/material/Select';
import { Box, Button, Typography, ClickAwayListener, FormControl, Grow, InputAdornment, Avatar, Menu, MenuItem, MenuList, Popper, TextField, Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import moment from 'moment'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Checkbox from '@mui/material/Checkbox';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';

function ValueLabelComponent() {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));


const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#3a8589',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3,
    },
}));



function AirbnbThumbComponent() {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
            <span className="airbnb-bar" />
        </SliderThumb>
    );
}

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
export default function Companies() {
    let navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);

    const AddService = () => {
        navigate('/addservice');
    }

    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [serviceDetails, setServiceDetails] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    // const [status, setStatus] = React.useState("all");
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [minState, setMinState] = React.useState(100);
    const [maxState, setMaxState] = React.useState(1000);
    const [priceRange, setPriceRange] = React.useState([0, 1000])
    const [selectedFilter, setSelectedFilter] = React.useState('all');
    const [filterBy, setFilterBy] = React.useState('status'); // Default to filter by status
    const [status, setStatus] = React.useState('all'); // Default status value
    const [minPrice, setMinPrice] = React.useState(''); // State for minimum price filter
    const [maxPrice, setMaxPrice] = React.useState('');  // State to store date filter value
    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
    };

    const handleStatusChange = (e) => {
        setPriceRange(100, 10000)
        setStatus(e.target.value);
    };

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
        setMinState(newValue[0]);
        setMaxState(newValue[1]);
    };

    const handleMaxPriceChange = (e) => {
        setMaxPrice(e.target.value);
    };
    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }
    const handleView = () => {
        setAnchorEl(null);
        setAnchorE2(null);
        setAlldata(allDetails);
        setOpenview(true)
    }



    const handleClickOpen = () => {
        setAnchorE2(null);
        setAnchorEl(null);
        setDeleteId(allDetails._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };
    const handleClose_menu = () => {
        setAnchorEl(null);
    };
    React.useEffect(() => {
        getServiceDetails()
    }, [])

    React.useEffect(() => {
        getServiceDetails()
    }, [rowsPerPage, page, search, status, priceRange])


    const getServiceDetails = async () => {
        await axios
          .get(
            process.env.REACT_APP_BASE_URL +
              `services/gettheservice?page=${page}&size=${rowsPerPage}&search=${search}&isService=${
                status === "all" ? "" : status
              }&pricing=${priceRange}`
          )
          .then((res) => {
            console.log(res.data.data)
            setServiceDetails(res.data.data);
            setTotal(res.data.totalcount);
          })
          .catch((error) => {
            console.log(error, "error");
          });
    }


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const handleCancelClick = () => {
        setSearch('');

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(serviceDetails);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        var fromvar = [...items]
        var intial = fromvar[result.source.index]


        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;
        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        setServiceDetails(fromvar);

        var body = {
            index_position: currElIndexNumber
        }
        axios.put(process.env.REACT_APP_BASE_URL + `services/updatepositioninservice/${final._id}`, body)
            .then((res) => {
                getServiceDetails();
            })
            .catch((err) => console.log(err.response, "drag & drop"));
    };

    const EditDetails = (row) => {
      console.log(row)
      console.log(allDetails)
        navigate('/editservice', { state: allDetails })
    }


    const DeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `services/deleteservice/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Service deleted successfully", { variant: 'success' })
                getServiceDetails()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                // handleClose()
            })
    }

    const handleChangeVisible = async (e, data) => {
        var body = {
            isService: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `services/updateboolean/${data._id}`, body)
            .then((res) => {
                console.log(res, "resssss");
                if (res.data.message == "Status Enabled") {
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                }
                else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                getServiceDetails();
            }).catch((err => {
                console.log("error while shop enable");
            }))
    }

    const handleRemove = () => {
        setPriceRange([100, 1000])
        setSelectedFilter('all')
        setStatus('all')

    }

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeCheck = async (e, data) => {
        var body = {
            isPrimary: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `services/updateprimarydetails/${data._id}`, body)
            .then((res) => {
                getServiceDetails();
            }).catch((err => {
                enqueueSnackbar(err.response.data.error, { variant: 'error' })
                console.log(err.response.data.error, "error while shop enable");
            }))
    }


    const [anchorE2, setAnchorE2] = React.useState(null);
    const [allDetails, setAllDetails] = React.useState('');



    const open1 = Boolean(anchorE2);
    const OpenMenu = (event, data) => {
        setAllDetails(data)
        setAnchorE2(event.currentTarget);
    };

    console.log(allDetails)
    const closeMenu = () => {
        setAnchorE2(null);
    };

    const sanitizedContent = DOMPurify.sanitize(alldata.Description, { ALLOWED_TAGS: ['img'] });


    const [fileTypes, setFileTypes] = React.useState([]);

    React.useEffect(() => {
        if (alldata && Array.isArray(alldata.additionalimage) && alldata.additionalimage.length > 0) {
            const fetchFileTypes = async () => {
                const types = await Promise.all(
                    alldata.additionalimage.map(async fileUrl => {
                        const type = await getFileType(fileUrl);
                        console.log(type, "typetype");
                        return type;
                    })
                );
                setFileTypes(types);
            };
    
            fetchFileTypes();
        } else {
            // Handle the case where alldata.additionalimage is not an array or is empty
            setFileTypes([]); // or you could handle it differently based on your needs
        }
    }, [alldata]);


    const getFileType = async (fileUrl) => {
        if (fileUrl.startsWith('blob:')) {
            try {
                const response = await axios.get(fileUrl, { responseType: 'blob' });
                return response.data.type;
            } catch (error) {
                console.error('Error fetching the file:', error);
                return 'video/mp4'
            }
        } else {
            let abc = inferMimeType(fileUrl);
            return abc
        }
    };

    const inferMimeType = (fileUrl) => {
        const extension = fileUrl.split('.').pop();
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return `image/${extension}`;
            case 'mp4':
            case 'webm':
            case 'ogg':
                return `video/${extension}`;
            default:
                return null;
        }
    };

    const formatPrice = (price) => {
        let priceStr = price.toString().replace(/,/g, '');
        priceStr = priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return priceStr;
    };

    return (
      <Box className="companies">
        <MiniDrawer type={4} />
        <Box component="section" className="contentWraper">
          <Box className="headingBox">
            <Typography component="h4" variant="h4" className="page-heading">
              Services
            </Typography>
            <Button
              variant="contained"
              className="addbtn"
              onClick={() => AddService()}
              style={{ marginTop: "20px", backgroundColor: "black" }}
            >
              {" "}
              <PlaylistAddRounded className="fz-18 mr-5" /> Add Service
            </Button>
          </Box>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            style={{ marginBottom: 10 }}
            alignItems="flex-end"
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl variant="outlined" fullWidth size="small">
                <TextField
                  id="filled-size-small"
                  variant="outlined"
                  size="small"
                  value={search}
                  onChange={handleSearchChange}
                  placeholder="Search Here..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon className="fz-18" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {search && (
                          <IconButton onClick={handleCancelClick}>
                            <CancelIcon className="fz-18" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
              <FormControl variant="outlined" fullWidth size="small">
                <Typography component="div" className="label">
                  <Box fontWeight="bold" display="inline">
                    Filter
                  </Box>
                </Typography>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  variant="outlined"
                  className="select"
                >
                  <MenuItem value={"all"} className="menuitems">
                    <Box fontWeight="bold" display="inline">
                      All
                    </Box>
                  </MenuItem>
                  <MenuItem value={true} className="menuitems">
                    <Box fontWeight="bold" display="inline">
                      Approved
                    </Box>
                  </MenuItem>
                  <MenuItem value={false} className="menuitems">
                    <Box fontWeight="bold" display="inline">
                      Rejected
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {status !== "all" && (
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Button
                  onClick={handleRemove}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    marginTop: "8px",
                  }}
                >
                  Reset
                </Button>
              </Grid>
            )}
          </Grid>

          <Box className="whiteContainer">
            <Box>
              <TableContainer component={Paper} id="table">
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  className=""
                >
                  <TableHead className="DT-head">
                    <TableRow className="">
                      {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px' }}>Image</TableCell> */}
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 20,
                          maxWidth: 20,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Service name
                      </TableCell>
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 20,
                          maxWidth: 20,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Talent name
                      </TableCell>
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 20,
                          maxWidth: 20,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Price(AED)
                      </TableCell>
                      {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight: 'bold' }}>Category</TableCell> */}

                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 30,
                          maxWidth: 30,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Status
                      </TableCell>
                      {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 10, maxWidth: 10, padding: '6px 16px', fontWeight: 'bold' }}>Primary</TableCell> */}

                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 30,
                          maxWidth: 30,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <TableBody
                          className="characters"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {serviceDetails && serviceDetails.length > 0 ? (
                            serviceDetails.map((row, i) => (
                              <Draggable
                                key={row._id}
                                draggableId={row._id}
                                index={i}
                              >
                                {(provided) => (
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className=""
                                  >
                                    {/* <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                                                                    {row.primaryimage?.length > 0 ? (
                                                                        <img
                                                                            src={process.env.REACT_APP_BASE_URL + `${row.primaryimage}`}
                                                                            height={50}
                                                                            width={50}
                                                                            style={{ borderRadius: '50%' }}
                                                                            alt="profile Image"
                                                                        />
                                                                    ) : (
                                                                        <Avatar
                                                                            sx={{ width: 32, height: 32, margin: 1 }}
                                                                            alt={row.username ? row.username.charAt(0).toUpperCase() : 'Y'} // Alt attribute for accessibility
                                                                        >
                                                                            {row.username ? row.username.charAt(0).toUpperCase() : 'Y'}
                                                                        </Avatar>
                                                                    )}
                                                                </TableCell> */}
                                    <TableCell
                                      className="DT-row"
                                      align="left"
                                      style={{ minWidth: 50 }}
                                    >
                                      {row && row.servicename}
                                    </TableCell>
                                    <TableCell
                                      className="DT-row"
                                      align="left"
                                      style={{ minWidth: 100 }}
                                    >
                                      {row && row.talent_id
                                        ? `${row.talent_id.name} ${row.talent_id.lastname}`
                                        : row.model_id
                                        ? `${row.model_id.name} ${row.model_id.lastname}`
                                        : row.studio_id
                                        ? `${row.studio_id.name} ${row.studio_id.lastname}`
                                        : "-"}{" "}
                                    </TableCell>
                                    <TableCell
                                      className="DT-row"
                                      align="left"
                                      style={{ minWidth: 50 }}
                                    >
                                      {" "}
                                      {row &&
                                        row.pricing &&
                                        formatPrice(row.pricing)}
                                    </TableCell>
                                    {/* <TableCell className='DT-row' align="left" style={{ minWidth: 30 }}> {row && row.Category_id?.length ? row.Category_id.map((data) => data.title).join(', ') : "-"}</TableCell > */}

                                    <TableCell
                                      className="DT-row"
                                      align="left"
                                      style={{ minWidth: 50 }}
                                    >
                                      <FormControlLabel
                                        className="m-0"
                                        control={
                                          <IOSSwitch
                                            onChange={(e) =>
                                              handleChangeVisible(e, row)
                                            }
                                            checked={row.isService}
                                            sx={{ m: 1 }}
                                          />
                                        }
                                      />
                                    </TableCell>

                                    {/* <TableCell align="left" component="th" scope="row" padding="none" className='DT-row'  >
                                                                    <Checkbox onChange={(e) => handleChangeCheck(e, row)} checked={row.isPrimary} style={{ color: 'black' }} />
                                                                </TableCell> */}
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      padding="none"
                                      className="DT-row"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Button
                                        id="basic-button"
                                        aria-controls={
                                          open1 ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open1 ? "true" : undefined
                                        }
                                        onClick={(e) => OpenMenu(e, row)}
                                      >
                                        <MoreVertIcon sx={{ color: "#000" }} />
                                      </Button>
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorE2}
                                        open={open1}
                                        onClose={closeMenu}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                      >
                                        <MenuItem
                                          onClick={() => EditDetails(row)}
                                        >
                                          <ModeEditIcon className="icons text-grey" />{" "}
                                          Edit
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) => handleClickOpen(row)}
                                        >
                                          <DeleteIcon className="icons text-grey" />{" "}
                                          Delete
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) => handleView(row)}
                                        >
                                          <RemoveRedEyeIcon className="icons text-grey" />{" "}
                                          View
                                        </MenuItem>
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <TableRow className="">
                              <TableCell
                                className="DT-row"
                                align="center"
                                colSpan={5}
                              >
                                No Data Found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="DT-pagination"
              />
              <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className="custom_modal"
              >
                <DialogTitle>
                  Would you like to delete this Service?
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    className="cancel_btn"
                    style={{ color: "#FFFFFF", backgroundColor: "#000" }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={DeleteService}
                    className="theme-btn"
                    style={{ color: "#FFFFFF", backgroundColor: "#000" }}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>

              <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className="custom_modal"
                maxWidth="md"
              >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  Service Details
                </DialogTitle>

                <DialogContent dividers>
                  <Grid
                    style={{ marginBottom: 15 }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                  >
                    <Box className="">
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 semibold"
                      >
                        Service:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 "
                      >
                        {alldata.servicename && alldata.servicename}
                      </Typography>
                    </Box>
                    {/* <Box className=''>
                                        <Typography component='span' variant='body1' className='fz-15 semibold'>Username: </Typography>
                                        <Typography component='span' variant='body1' className='fz-15 '>{alldata.username && alldata.username}</Typography>
                                    </Box> */}

                    {/* <Box className=''> */}
                    {/* <Typography component='span' variant='body1' className='fz-15 semibold'>Category: </Typography> */}
                    {/* <Typography component='span' variant='body1' className='fz-15 '>{alldata && alldata.Category_id?.length && alldata.Category_id.map((data) => data.category_name).join(', ')}</Typography> */}
                    {/* {alldata.Category_id && alldata.Category_id?.length > 0 &&alldata.Category_id.map((val)=>(
                                            <Typography component='span' variant='body1' className='fz-15 '>{val.category_name}</Typography>
                                        )) }  */}
                    {/* </Box> */}

                    <Box className="">
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 semibold"
                      >
                        Price($):{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 "
                      >
                        {alldata.pricing && alldata.pricing}
                      </Typography>
                    </Box>

                    <Box className="">
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 semibold"
                      >
                        Duration:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 "
                      >
                        {alldata.duration && alldata.duration}
                      </Typography>
                    </Box>
                    <Box className="">
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 semibold"
                      >
                        Location:{" "}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        className="fz-15 "
                      >
                        {alldata.location && alldata.location}
                      </Typography>
                    </Box>

                    {alldata.servicetype !== "Permanent" && (
                      <Box className="">
                        <Typography
                          component="span"
                          variant="body1"
                          className="fz-15 semibold"
                        >
                          Date:{" "}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body1"
                          className="fz-15 "
                        >
                          {alldata.startDate &&
                            moment(alldata.startDate).format("DD-MM-YYYY")}
                          <strong> - </strong>
                          {alldata.endDate &&
                            moment(alldata.endDate).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                    )}
                  </Grid>

                  <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={1}
                    className=" mb-10 align-items-center"
                  >
                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="carosuel mb-10">

                                            <Typography component='span' variant='body1' className='fz-15 semibold'>Primary Image: </Typography>

                                            <Swiper
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper "
                                                slidesPerView={1}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >
                                                <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${alldata && alldata.primaryimage}`} /></SwiperSlide>


                                            </Swiper>
                                        </div>

                                    </Grid> */}

                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="carosuel mb-10">

                                            <Typography component='span' variant='body1' className='fz-15 semibold'>Additional files: </Typography>

                                            <Swiper
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper "
                                                slidesPerView={1}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >
                                                {alldata && alldata.additionalimage && alldata.additionalimage?.length > 0 && alldata.additionalimage.map((tdata, i) => (
                                                    <>
                                                        <SwiperSlide>
                                                            {fileTypes[i]?.includes('image') ? <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />
                                                                :
                                                                <video controls type={fileTypes[i]} width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} />}
                                                        </SwiperSlide>
                                                    </>


                                                ))}
                                            </Swiper>

                                        </div>

                                    </Grid> */}

                    {alldata &&
                      alldata.addionalimage &&
                      alldata.addionalimage?.length > 0 && (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="carosuel mb-10">
                            <Typography
                              component="span"
                              variant="body1"
                              className="fz-15 semibold"
                            >
                              Additional files:{" "}
                            </Typography>

                            <Swiper
                              modules={[Navigation, Pagination]}
                              className="mySwiper "
                              slidesPerView={1}
                              pagination={{
                                clickable: true,
                              }}
                            >
                              {alldata &&
                                alldata.addionalimage &&
                                alldata.addionalimage?.length > 0 &&
                                alldata.addionalimage.map((tdata, i) => {
                                  //     console.log(tdata,"tdata");
                                  //     return <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                  const fileExtension = tdata
                                    .split(".")
                                    .pop()
                                    .toLowerCase();
                                  console.log(
                                    fileExtension,
                                    "fileExtensionfileExtension"
                                  );
                                  if (fileExtension === "mp4") {
                                    return (
                                      <SwiperSlide key={i}>
                                        <video
                                          src={
                                            process.env.REACT_APP_BASE_URL +
                                            `${tdata}`
                                          }
                                          type="video/mp4"
                                          width="100%"
                                          height={250}
                                          controls
                                        />
                                      </SwiperSlide>
                                    );
                                  } else if (
                                    ["jpg", "png", "jpeg"].includes(
                                      fileExtension
                                    )
                                  ) {
                                    return (
                                      <SwiperSlide key={i}>
                                        <img
                                          width={"100%"}
                                          height={250}
                                          src={
                                            process.env.REACT_APP_BASE_URL +
                                            `${tdata}`
                                          }
                                          alt="Additional"
                                        />
                                      </SwiperSlide>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                            </Swiper>
                          </div>
                        </Grid>
                      )}

                    {alldata &&
                      alldata.addionalvideo &&
                      alldata.addionalvideo?.length > 0 && (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="carosuel mb-10">
                            <Typography
                              component="span"
                              variant="body1"
                              className="fz-15 semibold"
                            >
                              Additional Videos:{" "}
                            </Typography>

                            <Swiper
                              modules={[Navigation, Pagination]}
                              className="mySwiper "
                              slidesPerView={1}
                              pagination={{
                                clickable: true,
                              }}
                            >
                              {alldata &&
                                alldata.addionalvideo &&
                                alldata.addionalvideo?.length > 0 &&
                                alldata.addionalvideo.map((tdata, i) => (
                                  <SwiperSlide>
                                    {" "}
                                    <video
                                      controls
                                      width={"100%"}
                                      height={250}
                                      src={
                                        process.env.REACT_APP_BASE_URL +
                                        `${tdata}`
                                      }
                                    />
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                          </div>
                        </Grid>
                      )}
                  </Grid>

                  <Typography
                    component="div"
                    variant="body1"
                    className="fz-14 semibold"
                  >
                    Description:{" "}
                  </Typography>
                  {sanitizedContent ? (
                    <div
                      className="w-full editor_view"
                      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                    />
                  ) : (
                    <Typography className="p18_tag">
                      There is no description
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    className="cancel_btn"
                    style={{ color: "#000" }}
                    onClick={handleClickClose}
                  >
                    Close
                  </Button>
                </DialogActions>
              </BootstrapDialog>
            </Box>
          </Box>
        </Box>
      </Box>
    );
}