import { PlaylistAddRounded, SearchOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios'
import DOMPurify from 'dompurify';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar, SnackbarProvider } from 'notistack'
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Select from '@mui/material/Select';
import { Box, Button, Typography, ClickAwayListener, FormControl, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField, Grid, Avatar, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import moment from 'moment'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Checkbox from '@mui/material/Checkbox';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonPinIcon from '@mui/icons-material/PersonPin';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
export default function Users() {
    let navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);

    const AddService = () => {
        navigate('/addservice');
    }




    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [serviceDetails, setServiceDetails] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [editData, setEditData] = React.useState('');



    const [deleteid, setDeleteId] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [allDetails, setAllDetails] = React.useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading,setLoading] = React.useState(false);
    const open1 = Boolean(anchorE2);


    const OpenMenu = (event, data) => {
        setAllDetails(data)
        setSelectedRow(data)
        setAnchorE2(event.currentTarget);
    };


    const closeMenu = () => {
        setAnchorE2(null);
        setAllDetails(null)
        setSelectedRow(null)
    };
    const handleViewCustomer = () => {
        setAnchorE2(null);
        setAlldata(allDetails);
        setOpenview(true)

    }

    const EditDetails = (data) => {
        console.log(data._id, "isssssss");
        setEditData(data)
        navigate('/editcustomer', { state: allDetails })
    }
    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }
    const handleView = (data) => {
        if(!data.following.length) return;
        console.log(data.following, "dataaaaa");
        // let newdata = data.following.map((foloing) => {
        setAlldata(data);
        // })
        setFollowingdata(data.following)
        setOpenview(true)
    }


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const handleCancelClick = () => {
        setSearch('');

    };
    // const handleClickOpen = (data) => {
    //     console.log(data, "aaaaaaaa");
        // setAnchorEl(null);
    //     setDeleteId(data)
    //     setOpen(true);
    // };

    const handleClickOpen = () => {
        if (selectedRow) {
            setDeleteId(selectedRow._id);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };
    const handleClose_menu = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
        getUsers()
    }, [rowsPerPage, page, search])

    const [followingdata, setFollowingdata] = React.useState([])
    console.log(followingdata,"followingdatafollowingdata")

    const getUsers = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `talents/getadminusers?page=${page}&size=${rowsPerPage}&search=${search}&role='User`)
            .then((res) => {
                console.log(res.data, "res.datares.data");
                const filterdata = res.data.finduser;
                const followersData = filterdata.flatMap(talent => talent.following || []);
                console.log(followersData,"ggggggggggggggggggggggggg")
                setUsers(res.data.finduser)
                setTotal(res.data.total)
                
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const AddCustomer = () => {
        navigate('/addcustomer')
    }


    const DeleteService = async () => {
        setLoading(true)
        await axios.put(process.env.REACT_APP_BASE_URL + `talents/deleteusers/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Deleted successfully", { variant: 'success' })
                getUsers()
                setLoading(false)
                handleClose()
            })
            .catch((error) => {
                console.log(error);
                
            })
            .finally(()=>{
                setLoading(false)
            })
    }

    const handleChangeVisible = async (e, data) => {
        var body = {
            isService: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `services/updateboolean/${data._id}`, body)
            .then((res) => {
                getUsers();
            }).catch((err => {
                console.log("error while shop enable");
            }))
    }

    const handleRemove = () => {
        setSearch("")
    }

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeCheck = async (e, data) => {
        var body = {
            setService: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `services/updatesetService/${data._id}`, body)
            .then((res) => {
                getUsers();
            }).catch((err => {
                enqueueSnackbar(err.response.data.error, { variant: 'error' })
                console.log(err.response.data.error, "error while shop enable");
            }))
    }

    const sanitizedContent = DOMPurify.sanitize(alldata.Description, { ALLOWED_TAGS: ['img'] });

    return (
      <Box className="companies">
        <MiniDrawer type={3} />
        <Box component="section" className="contentWraper">
          <Box className="headingBox">
            <Typography component="h4" variant="h4" className="page-heading">
              Customers
            </Typography>
            <Button
              variant="contained"
              className="addbtn"
              onClick={() => AddCustomer()}
              style={{ marginTop: "20px", backgroundColor: "black" }}
            >
              {" "}
              <PlaylistAddRounded className="fz-18 mr-5" />
              Add Customer
            </Button>
          </Box>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl variant="outlined" size="small">
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <TextField
                    id="filled-size-small"
                    variant="outlined"
                    size="small"
                    value={search}
                    onChange={handleSearchChange}
                    placeholder="Search Here..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon className="fz-18" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {search && (
                            <IconButton onClick={handleCancelClick}>
                              <CancelIcon className="fz-18" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </FormControl>
          </Grid>
          <Box mt={2} className="whiteContainer">
            <Box>
              <TableContainer component={Paper} id="table">
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  className=""
                >
                  <TableHead className="DT-head">
                    <TableRow className="">
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 20,
                          maxWidth: 20,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Image
                      </TableCell>
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 20,
                          maxWidth: 20,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 100,
                          maxWidth: 150,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 30,
                          maxWidth: 20,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        Address
                      </TableCell>
                      {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight:'bold' }}>City</TableCell>
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight:'bold' }}>State</TableCell> */}
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{
                          minWidth: 20,
                          maxWidth: 20,
                          padding: "6px 16px",
                          fontWeight: "bold",
                        }}
                      >
                        # of following
                      </TableCell>
                      <TableCell
                        align="left"
                        className="TRow DT-checkbox"
                        style={{ minWidth: 10, fontWeight: "bold" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="characters ">
                    {users && users?.length > 0 ? (
                      users.map((row, i) => (
                        <>
                          <TableRow className="">
                            <TableCell
                              className="DT-row"
                              align="left"
                              style={{ minWidth: 50 }}
                            >
                              {row?.profile?.length > 0 ? (
                                <img
                                  src={
                                    process.env.REACT_APP_BASE_URL +
                                    `${row?.profile}`
                                  }
                                  height={50}
                                  width={50}
                                  style={{ borderRadius: "50%" }}
                                  alt="profileImg"
                                />
                              ) : (
                                <Avatar
                                  sx={{ width: 32, height: 32, margin: 1 }}
                                  alt={
                                    row.username
                                      ? row.username.charAt(0).toUpperCase()
                                      : "A"
                                  }
                                >
                                  {row.username
                                    ? row.username.charAt(0).toUpperCase()
                                    : "A"}
                                </Avatar>
                              )}
                            </TableCell>
                            <TableCell
                              className="DT-row"
                              align="left"
                              style={{ minWidth: 20, maxWidth: 20 }}
                            >{`${row.name ? row.name : ""}${
                              row.name && row.lastname ? " " : ""
                            }${row.lastname ? row.lastname : ""}`}</TableCell>
                            <TableCell
                              className="DT-row"
                              align="left"
                              style={{ minWidth: 20, maxWidth: 20 }}
                            >
                              {row && row.email ? row.email : "-"}
                            </TableCell>
                            <TableCell
                              className="DT-row"
                              align="left"
                              style={{ minWidth: 20, maxWidth: 20 }}
                            >
                              {row && row.location ? row.location : "-"}
                            </TableCell>
                            {/* <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>{row && row.city ? row.city : "-"}</TableCell >
                                                <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>{row && row.state ? row.state : "-"}</TableCell > */}
                            <TableCell
                              className="DT-row"
                              align="left"
                              style={{
                                minWidth: 20,
                                maxWidth: 20,
                                cursor: "pointer",
                              }}
                              onClick={(e) => handleView(row)}
                            >
                              {row && row.following && row.following.length > 0
                                ? row.following.length
                                : "-"}{" "}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              className="DT-row"
                              style={{ cursor: "pointer" }}
                            >
                              <Button
                                id="basic-button"
                                aria-controls={open1 ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={(e) => OpenMenu(e, row)}
                              >
                                {" "}
                                <MoreVertIcon sx={{ color: "#000" }} />
                              </Button>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorE2}
                                open={open1}
                                onClose={closeMenu}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem onClick={(e) => EditDetails(row)}>
                                  <ModeEditIcon className="icons text-grey" />{" "}
                                  Edit
                                </MenuItem>
                                <MenuItem onClick={(e) => handleClickOpen(row)}>
                                  <DeleteIcon className="icons text-grey" />{" "}
                                  Delete
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    ) : (
                      <TableRow className="">
                        <TableCell
                          className="DT-row"
                          align="center"
                          colSpan={5}
                          sx={{ marginRight: "20px" }}
                        >
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="DT-pagination"
              />
              <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className="custom_modal"
              >
                <DialogTitle>
                  Would you like to delete this Customer?
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    className="cancel_btn"
                    style={{ color: "#FFFFFF", backgroundColor: "#000" }}
                  >
                    No
                  </Button>
                  <Button
                    onClick={DeleteService}
                    className="theme-btn"
                    style={{ color: "#FFFFFF", backgroundColor: "#000" }}
                  >
                    {loading ? "Please Wait" : "Yes"}
                  </Button>
                </DialogActions>
              </Dialog>

              <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className="custom_modal"
                maxWidth="md"
              >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  Talent Details
                </DialogTitle>

                <DialogContent dividers>
                  <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={1}
                    className=" mb-5 align-items-center"
                  >
                    {followingdata &&
                      followingdata.length > 0 &&
                      followingdata.map((data) => (
                        <Card
                          sx={{
                            minWidth: 275,
                            margin: 2,
                            border: "1px solid #ccc",
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
                          }}
                        >
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                            >
                              {" "}
                            </Typography>
                            <Box className="">
                              <Avatar
                                src={
                                  process.env.REACT_APP_BASE_URL +
                                  `${data?.UserId?.profile}`
                                }
                                sx={{ width: 80, height: 80 }}
                              />
                              <Typography
                                component="span"
                                variant="body1"
                                className="fz-15 "
                              >
                                Name: {data.UserId && data.UserId.name}
                              </Typography>
                            </Box>
                            <Box className="">
                              <Typography
                                component="span"
                                variant="body1"
                                className="fz-15 "
                              >
                                Email:{" "}
                                {data?.UserId?.email && data?.UserId?.email}
                              </Typography>
                            </Box>
                            {/* <Box className=''>
                                                
                                                    <Link style={{ cursor: 'pointer' }} href={data.UserId && data.UserId.uniqueURL} component='span' variant='body1' className='fz-15 '>URL: {data.UserId && data.UserId.uniqueURL}</Link>
                                                </Box> */}
                          </CardContent>
                        </Card>
                      ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="cancel_btn"
                    style={{ color: "#FFFFFF", backgroundColor: "#000" }}
                    onClick={handleClickClose}
                  >
                    Close
                  </Button>
                </DialogActions>
              </BootstrapDialog>
            </Box>
          </Box>
        </Box>
      </Box>
    );
}