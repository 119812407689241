import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Drawer from '../components/drawer'
import ForgotPassword from '../pages/Authentication/ForgotPassword'
import Login from '../pages/Authentication/Login'
import Userlogin from '../pages/Authentication/Userlogin'
import ResetPassword from '../pages/Authentication/ResetPassword'
import SetPassword from '../pages/Authentication/SetPassword'
import PasswordExpird from '../pages/Authentication/setpasswordExpire'


import Service from '../pages/Home/Service'
import AddService from '../pages/Home/Service/AddService'
// import AddUser from '../pages/Home/Talents/AddUser'
import EditServices from '../pages/Home/Service/EditService'
import ViewService from '../pages/Home/Service/viewservice'




import Dashboard from '../pages/Home/Dashboard'
import Settings from '../pages/Home/settings'
// import Settings_sidebar from '../pages/Home/Settings/Sidebar'
import Category from '../pages/Home/Category'
import AddCategory from '../pages/Home/Category/AddCategory'
import EditCategory from '../pages/Home/Category/editCategory'

import SubCategory from '../pages/Home/subcategory'
import AddSubCategory from '../pages/Home/subcategory/addSubcategory'
import EditSubCategory from '../pages/Home/subcategory/editSubcategory'

import Models from '../pages/Home/Models'
import AddModels from '../pages/Home/Models/Addmodels'
import EditModels from '../pages/Home/Models/Editmodels'
import ViewModels from'../pages/Home/Models/viewModle'
import Booking from '../pages/Home/Booking'
import ViewBooking from '../pages/Home/Booking/viewBooking'



import Studio from '../pages/Home/Studio'
import AddStudio from '../pages/Home/Studio/AddStudio'
import EditStudio from '../pages/Home/Studio/EditStudio'
import ViewStudio from '../pages/Home/Studio/viewstudio'

import Chat from '../pages/Home/Chat'
import Message from '../pages/Home/Chat/message'

import PrivateRoute from '../components/privateRoute'

import Users from '../pages/Home/Usermanagement'
import AddCustomer from '../pages/Home/Usermanagement/addcustemor'
import EditCustomer from '../pages/Home/Usermanagement/editcustomer'


import Talent from '../pages/Home/talents'
import Addtalent from '../pages/Home/talents/payment'
import Edittalent from '../pages/Home/talents/edittalent'
import ViewTalent from '../pages/Home/talents/viewtalent'


import User from '../pages/Home/AdminUserManegment'
import AddUser from '../pages/Home/AdminUserManegment/addUser'
import EditUser from '../pages/Home/AdminUserManegment/edituser'

import Role from '../pages/Home/Role manegment'
import AddRole from '../pages/Home/Role manegment/addrole'
import EditRole from '../pages/Home/Role manegment/editrole'
import Admin from '../pages/Home/AdminUserManegment/adminProfile'

import Test from '../pages/Authentication/test';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkout from '../pages/Home/Booking/checkout';
import Model from '../pages/Home/Models/model';
import Wish from '../pages/Home/Thankyou/wishpage'

const stripePromise = loadStripe('pk_test_51PlPadGnDlzJZxI6e1UecZz58DxaAllLhxkTHoDlqLo6diDvZyVYg8O0ei8VeVHQ1o17idgUoYUGESTuSHlShCAF00I6yExs1j')

export default function Routers() {

    return (



        <Router>
            <Routes>
                <Route exact path='/' element={<Login />}></Route>
                <Route exact path = '/test' element ={<Test/>}> </Route>
                <Route exact path='/userlogin' element={<Userlogin/>}></Route>
                <Route exact path='/thankyou' element={<Wish/>}></Route>


                <Route exact path='/forgotpassword' element={<ForgotPassword />}></Route>
                <Route exact path='/reset-password' element={<ResetPassword />}></Route>
                <Route exact path='/set-password/:id/:token' element={<SetPassword />} />
                <Route exact path='/expirde/' element={<PasswordExpird />} />

                

                <Route exact path='/home' element={<PrivateRoute><Drawer /></PrivateRoute>}></Route>
                <Route exact path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>}></Route>

                <Route exact path='/service' element={<PrivateRoute><Service/></PrivateRoute>}></Route>
                <Route exact path='/addservice' element={<PrivateRoute><AddService/></PrivateRoute>}></Route>
                <Route exact path='/editservice' element={<PrivateRoute><EditServices/></PrivateRoute>}></Route>
                <Route exact path='/viewservice' element={<PrivateRoute><ViewService/></PrivateRoute>}></Route>



              

                <Route exact path='/category' element={<PrivateRoute><Category /></PrivateRoute>}></Route>
                <Route exact path='/addcategory' element={<PrivateRoute><AddCategory /></PrivateRoute>}></Route>
                <Route exact path='/editcategory' element={<PrivateRoute><EditCategory /></PrivateRoute>}></Route>

                <Route exact path='/subcategory' element={<PrivateRoute><SubCategory /></PrivateRoute>}></Route>
                <Route exact path='/addsubcategory' element={<PrivateRoute><AddSubCategory /></PrivateRoute>}></Route>
                <Route exact path='/editsubcategory' element={<PrivateRoute><EditSubCategory /></PrivateRoute>}></Route>



                <Route exact path='/models' element={<PrivateRoute><Models /></PrivateRoute>}></Route>
                <Route exact path='/addmodels' element={<PrivateRoute><AddModels /></PrivateRoute>}></Route>
                <Route exact path='/editmodels' element={<PrivateRoute><EditModels /></PrivateRoute>}></Route>
                <Route exact path='/viewmodels' element={<PrivateRoute><ViewModels /></PrivateRoute>}></Route>
                <Route exact path='/models' element={<PrivateRoute><Model /></PrivateRoute>}></Route>

                <Route exact path='/booking' element={<PrivateRoute><Booking /></PrivateRoute>}></Route>
                <Route exact path='/viewbooking' element={<PrivateRoute><ViewBooking /></PrivateRoute>}></Route>
                {/*  */}
                {/* <Route exact path='/checkout' element={<PrivateRoute><Checkout /></PrivateRoute>}></Route>
                <Elements stripe={stripePromise}>
                <Checkout />
                </Elements> */}
                

                <Route exact path='/studio' element={<PrivateRoute><Studio /></PrivateRoute>}></Route>
                <Route exact path='/addstudio' element={<PrivateRoute><AddStudio /></PrivateRoute>}></Route>
                <Route exact path='/editstudio' element={<PrivateRoute><EditStudio /></PrivateRoute>}></Route>
                <Route exact path='/viewstudio' element={<PrivateRoute><ViewStudio/></PrivateRoute>}></Route>
                <Route exact path='/talent' element={<PrivateRoute><Talent /></PrivateRoute>}></Route>
                <Route exact path='/addtalent' element={<PrivateRoute><Addtalent /></PrivateRoute>}></Route>
                <Route exact path='/edittalent' element={<PrivateRoute><Edittalent /></PrivateRoute>}></Route>
                <Route exact path='/talentview' element={<PrivateRoute><ViewTalent/></PrivateRoute>}></Route>

                <Route exact path='/chat' element={<PrivateRoute><Chat /></PrivateRoute>}></Route>
                <Route exact path='/message' element={<PrivateRoute><Message /></PrivateRoute>}></Route>

                <Route exact path='/customer' element={<PrivateRoute><Users/></PrivateRoute>}></Route>
                <Route exact path='/addcustomer' element={<PrivateRoute><AddCustomer /></PrivateRoute>}></Route>
                <Route exact path='/editcustomer' element={<PrivateRoute><EditCustomer/></PrivateRoute>}></Route>
                <Route exact path='/adminprofile' element={<PrivateRoute><Admin/></PrivateRoute>}></Route>
                
                <Route exact path='/setting' element={<Settings/>}></Route>
                {/* <Route exact path='/setting/addstatus' element={<Addstatus/>}></Route>
                <Route exact path='/setting/addindustry' element={<Addindustry/>}></Route>
                <Route exact path='/setting/addownership' element={<Addownership/>}></Route>
                <Route exact path='/setting/addroles' element={<Addroles/>}></Route>  */}

                <Route exact path='/user' element={<PrivateRoute><User /></PrivateRoute>}></Route>
                <Route exact path='/adduser' element={<PrivateRoute><AddUser /></PrivateRoute>}></Route>
                <Route exact path='/edituser' element={<PrivateRoute><EditUser /></PrivateRoute>}></Route>
                
                <Route exact path='/role' element={<PrivateRoute><Role/></PrivateRoute>}></Route>
                <Route exact path='/addrole' element={<PrivateRoute><AddRole /></PrivateRoute>}></Route>
                <Route exact path='/editrole' element={<PrivateRoute><EditRole /></PrivateRoute>}></Route>
                <Route path='/checkout' element={
                   <PrivateRoute>
                     <Elements stripe={stripePromise}>
                     <Checkout />
                     </Elements>
                    </PrivateRoute>
                   } />
            </Routes>

        </Router>

    )

}


