// src/firebase/firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/database'; // Import database module from compat

const firebaseConfig = {
  apiKey: "AIzaSyCZZtiypfAxh2fXDosLXK9Z92a_EJujX4s",
  authDomain: "chatapp-rn-79e4e.firebaseapp.com",
  databaseURL: "https://chatapp-rn-79e4e-default-rtdb.firebaseio.com",
  projectId: "chatapp-rn-79e4e",
  storageBucket: "chatapp-rn-79e4e.appspot.com",
  messagingSenderId: "512330630070",
  appId: "1:512330630070:web:7e12f53ca543f2142a7717",
  measurementId: "G-VHLEHZY7BJ"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;

