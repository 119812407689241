import React, { useEffect, useState } from "react";
// import { storage, messaging } from '../firebase'
import { collection, query, where, onSnapshot, addDoc, Timestamp, orderBy, setDoc, doc, getDoc, updateDoc, } from "firebase/firestore";
import { getDatabase, set, push } from "firebase/compat/database";
import 'firebase/compat/database';
import Copylink from '../../../assets/images/copy.svg'
import Message from './message'
import VideocamIcon from '@mui/icons-material/Videocam';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography, Avatar, Input, InputAdornment, Paper, Button, FormControl, OutlinedInput, InputBase, Tabs, Tab, Card, CardContent } from '@mui/material';
import axios from "axios";
import { Send, AddPhotoAlternate, AttachFile, KeyboardVoice } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useCookies } from 'react-cookie';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSnackbar, SnackbarProvider } from 'notistack'
import MiniDrawer from '../../../components/drawer'
import Grid from '@mui/material/Grid';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { logRoles } from "@testing-library/react";

const Chat = (props) => {
    // let navigation = useNavigate()
    const scrollRef = React.useRef();
    console.log(scrollRef,"scrollRef")
    const inputRef = React.useRef(null);
    const storage = getStorage()
    const [open, setOpen] = React.useState(false);
    const [newtalent, setnewtalent] = React.useState(false);


    const [cookies, setCookie, removeCookie] = useCookies(['value'])
    // const { enqueueSnackbar } = useSnackbar()
    const [deleteid, setData] = useState('');
    // const [alldata, setAllData] = useState([]);
    const [alldetails, setAll] = useState([]);
    const [talentdata, setTalentdata] = useState([]);
    const [userdata, setUserdata] = useState([]);
    const [modeldata,setModeldata] = useState([]);
    
    const [studiodata, setStudiodata] = useState([]);
    const [details, setDetails] = useState('User');
    let adminget = JSON.parse(localStorage.getItem("userData"))
    const [allmessages, setAllmessages] = useState([]);
    const [admindetails, setAdminDetails] = React.useState('')
    const [adminMessage, setAdminMessage] = React.useState([])
    const [talentMessage, setTalentMessage] = React.useState([])
    console.log(talentMessage,"talentMessagetalentMessage in firebase")
    const [modelMessage, setModelmessage] = React.useState([])

    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [mediaType, setMediaType] = React.useState('')
    const [sendmessages, setSendMessages] = React.useState('')
    const [sendmessagestalent, setSendMessagestalent] = React.useState('')

    const [receiverDetails, setReceiverDetails] = React.useState('')
    const [userchat, setUserChat] = React.useState('')
    const [talentchat, setTalentChat] = React.useState('')
    console.log(userchat,"talentchattalentchat in firebase")
    const [modelchat, setModelChat] = React.useState('')
    const [studiochat, setStudiochat] = React.useState('')
    console.log(modeldata,'modelchat details in Chat ')



    //   console.log(talentchat,"talentchattalentchat");

    //   console.log(userchat, "userchatuserchat");
    //   console.log(talentchat, "talentchattalentchat");

    React.useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [adminMessage]);

    React.useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [talentMessage]);


    React.useMemo(() => {
        if (userchat && userchat === 'Talent') {
            let test = alldetails.filter((data) => data.talentId === userchat.user_id)
            setReceiverDetails(test[0])
        }
        else if (userchat && userchat === 'User') {
            let test = alldetails.filter((data) => data.talentId === userchat.user_id)
            setReceiverDetails(test[0])
        }else if(userchat && userchat === 'Model'){
            let test = alldetails.filter((data)=> data.talentId === userchat.user_id)
            setReceiverDetails(test[0])
        }else if(userchat && userchat === 'Studio'){
            let test = alldetails.filter((data)=> data.talentId === userchat.user_id)
            setReceiverDetails(test[0])
        }
    }, [alldetails])


    React.useEffect(() => {
        const fetchData = async () => {
            const dbRef = firebase.database().ref();
            dbRef.on('value', snapshot => {
                let admindetails = snapshot.val().Users
                let all = Object.values(admindetails);
                let userdet = all.filter((data) => data.role === 'User')
                let talentdet = all.filter((data) => data.role === 'Talent')
                let modeldet = all.filter((data) => data.role === 'Model')
                let studiodet = all.filter((data) => data.role === 'Studio')
                setUserdata(userdet)
                setTalentdata(talentdet)
                setModeldata(modeldet)
                setStudiodata(studiodet)
                setAll(all)
            });
        };
        fetchData();
        return () => {
            firebase.database().ref().off();
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const dbRef = firebase.database().ref();
            dbRef.on('value', snapshot => {
                let admindetails = snapshot.val().Users
                let admin = Object.values(admindetails);
                setAll(admin)
                let test = admin.filter((data) => data.role == 'Admin')
                setAdminDetails(test)
            });
        };

        const FetchMessage = async () => {
            const FindId = firebase.database().ref('messages');
            FindId.on('value', (snapshot) => {
                const messages = [];
                snapshot.forEach((childSnapshot) => {
                    const Id = childSnapshot.key;
                    // console.log(Id, "HhhhhhhhHHHhHHHHhHHHHHHhhHhh")
                    const message = { ...childSnapshot.val(), id: Id };
                    messages.push(message);
                });
                setAllmessages(messages);
                // console.log(messages,"messagesmessagesmessagesmessagesmessages")
            });
        }
        fetchData();
        FetchMessage();
        return () => {
            firebase.database().ref().off();
        };
    }, []);
    const handleChangeDetails = (event, newValue) => {
        setDetails(newValue);
        setUserChat('')
        setTalentChat('')
        setModelChat('')
    };

    function convertDateFormat(originalDate) {
        const parts = originalDate.split(", ");
        const [datePart, timePart] = parts[0].split("/");
        const [month, day, year] = [datePart, timePart, parts[1]].map(part => parseInt(part));
        const [time, period] = parts[1].split(" ");
        return `${day}/${month}/${year}, ${time} ${period}`;
    }

    //user
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage(e)
        }
    }


    const RemoveMessageNew = (data) => {
        const messageRef = firebase.database().ref(`/messages/${data.id}`);
        messageRef.remove()
        handleClose()
    }
    const RemoveMessage = (data) => {
        setData(data)
        setOpen(true)
    }
    console.log(userchat,'userchat')
    // React.useMemo(() => {
    //     if (allmessages?.length > 0) {
    //         let adminmessages = allmessages.filter(message => message.sender === adminget._id || message.receiver === adminget._id);
    //         //   let test = adminmessages.filter((data) => data.sender === userchat && userchat.user_id || data.receiver === userchat && userchat.user_id)
    //         if (userchat) {
    //             let test = adminmessages.filter((data) => data.sender == userchat.talentId || data.receiver == userchat.talentId)
    //             setAdminMessage(test)
    //         }
    //         else if (talentchat) {
    //             let test1 = adminmessages.filter((data) => data.sender == talentchat.talent_id || data.receiver == talentchat.talent_id)
    //             setTalentMessage(test1)
    //         }
    //         else if (modelchat) {
    //             let test1 = adminmessages.filter((data) => data.sender == modelchat.talent_id || data.receiver == modelchat.talent_id)
    //             setModelmessage(test1)
    //         }


    //         //   setAdminMessage(adminmessages)
    //     }
    // }, [allmessages, userchat, talentchat, modelchat])


    React.useMemo(() => {
        if (allmessages?.length > 0) {
            // Filter messages that have defined sender and receiver properties
            let adminmessages = allmessages.filter(message => 
                (message?.sender && message?.receiver) && 
                (message.sender === adminget._id || message.receiver === adminget._id)
            );
            console.log(adminmessages,'adminmessagesadminmessages');
    
            if (userchat) {
                console.log(userchat,'userchat in admin part')
                let test = adminmessages.filter(data => 
                    data.sender === userchat.talentId || data.receiver === userchat.talentId
                );
                setAdminMessage(test);
            }
            else if (talentchat) {
                let test1 = adminmessages.filter(data => 
                    data.sender === talentchat.talentId || data.receiver === talentchat.talentId
                );
                setTalentMessage(test1);
            }
            else if (modelchat) {
                let test1 = adminmessages.filter(data => 
                    data.sender === modelchat.talentId || data.receiver === modelchat.talentId
                );
                setModelmessage(test1);
            }
        }
    }, [allmessages, userchat, talentchat, modelchat]);
    

    // console.log(allmessages,"allmessages in receiver point")

    const handleClose = () => {
        setOpen(false);
    };


    const uploadImage = async (localImageUri) => {
        return new Promise(async (resolve, reject) => {
            const storageRef = ref(storage, localImageUri.type.includes('image') ? `/images/${localImageUri.name}` : `/videos/${localImageUri.name}`);
            try {
                await uploadBytes(storageRef, localImageUri);
                console.log('File uploaded successfully!');
                const downloadURL = await getDownloadURL(storageRef);
                resolve(downloadURL);
            } catch (error) {
                console.error('Error uploading file:', error);
                reject(error);
            }
        });
    };

    const handleBack = () => {
        setUserChat('')
    }

    const ChatMessageUser = (data) => {
       
        setUserChat(data)
    }

    const ChatMessageTalent = (data) => {
        setTalentChat(data)
    }

    const ChatMessagemodel = (data) => {
        setModelChat(data)
    }

    const ChatMessagestudio = (data) => {
        setStudiochat(data)
    }

    const multipleImgChange = (e, index) => {
        setMediaType('image')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        console.log(fileObj,'fileObjfileObj')
        var data = state1.filter(x => x != null)
        var data1 = state2.filter(x => x != null)
        setState1(data)
        setState2(data1)
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const handleVideoUpload = (e, index) => {
        setMediaType('video')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = state1.filter(x => x != null)
        var data1 = state2.filter(x => x != null)
        setState1(data)
        setState2(data1)
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }

    const handleSendMessage = async () => {
        var multipleImage = state2.filter(x => x != null);
        if (multipleImage && multipleImage.length > 0) {
            multipleImage.forEach(async (mediaUri) => {
                const messageType = mediaUri.type;
                const mediaMessageRef = firebase.database().ref('messages').push();
                const imageUrl = await uploadImage(mediaUri)
                mediaMessageRef.set({
                    sender: adminget._id,
                    receiver: userchat && userchat.talentId,
                    mediaType: messageType,
                    media: imageUrl,
                    message: '',
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                    status: 'sent',
                    // enquiryId: "66ceacd736105276bd6a79d8"
                    enquiryId:allmessages.enquiryId
                });
                console.log(mediaMessageRef,'mediaMessageRefmediaMessageRef')
                setState1([])
                setState2([])
                const messageBody = {
                    "to": receiverDetails.fcm,
                    "notification": {
                        "body": '',
                        "title": `${userchat && userchat.name} sent an image`,
                        "notificationType": 'Message'
                    },
                }
                fetch('https://fcm.googleapis.com/fcm/send', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
                    },
                    body: JSON.stringify(messageBody),
                }).then((res) => console.log(res, 'notisentresponse')).catch((err) => console.log(err, "notinotsentcatch"))
                console.log(`${messageType} message sent successfully!`);
            })
        }

        if (sendmessages !== '') {
            console.log(adminget._id,"inside the message",userchat.talentId)
            const messageRef = firebase.database().ref('messages').push();
            messageRef.set({
                sender: adminget._id,
                receiver: userchat && userchat.talentId,
                message: `${sendmessages}`,
                mediaType: '',
                media: '',
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                status: 'sent',
                readBy: [userchat.talentId],
                enquiryId: "66ceacd736105276bd6a79d8"
                // enquiryId: allmessages.enquiryId
            });
            setSendMessages('')

            const messageBody = {
                "to": receiverDetails.fcm,
                "notification": {
                    "body": `${sendmessages}`,
                    "title": `${userchat && userchat.name} sent an message`,
                    "notificationType": 'Message'
                },
            }
            fetch('https://fcm.googleapis.com/fcm/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
                },
                body: JSON.stringify(messageBody),
            }).then((res) => console.log(res, 'notisentresponse')).catch((err) => console.log(err, "notinotsentcatch"))
            console.log(`message sent successfully!`);
        }
    }


    //Talent 

    const [talentstate1, setTalentState1] = React.useState([])
    const [talentstate2, setTalentState2] = React.useState([])

    const handleBackTalent = () => {
        setTalentChat('')
        setModelChat('')
        setStudiochat('')
    }

    const handleBackUser = () => {
        setnewtalent(false)
        setTalentChat('')
        setModelChat('')
        setStudiochat('')
        // setUserChat('')
    }
 


    const TalentImageChange = (e, index) => {
        setMediaType('image')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setTalentState1(prevValue => prevValue.concat(fileArray))
        setTalentState2(prevValue => prevValue.concat(filepath))
    }

    const handleVideoUploadTalent = (e, index) => {
        setMediaType('video')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setTalentState1(prevValue => prevValue.concat(fileArray))
        setTalentState2(prevValue => prevValue.concat(filepath))
    }

    const indexDeleteTalent = (e, index) => {
        var filteredpreview = talentstate1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = talentstate1.filter((val, i) => {
            return i !== index;
        });
        setTalentState1(filteredpreview);
        setTalentState2(filteredraw);
    }

    const _handleKeyDownTalent = (e) => {
        if (e.key === 'Enter') {
            handleSendMessageTalent(e)
        }
    }


    const handleSendMessageUser = async () => {
        
          try {
            const messageRef = firebase.database().ref('messages').push();
            await messageRef.set({
              sender: adminget._id,
              receiver: userchat && userchat.user_id, 
              message: '', 
              mediaType:  '',
              media:  '',
              timestamp: firebase.database.ServerValue.TIMESTAMP,
              status: 'sent',
              enquiryId: '66cea7ef524e207e93cd2e3b' 
            });
      
            const notificationBody = {
              "to": receiverDetails.fcm, 
              "notification": {
                "body": '',
                "title": `${userchat && userchat.name} sent a message`,
                "notificationType": 'Message'
              },
            };
      
            // Send FCM notification to the user
            await fetch('https://fcm.googleapis.com/fcm/send', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
              },
              body: JSON.stringify(notificationBody),
            });
      
            console.log("Message sent to user successfully!");
      
          } catch (error) {
            console.log("Error sending message to user", error);
          }
        
      };
      


    const NavigateData1 = async (val) => {
        try {
            console.log(userdata,"userchat in users message")
            let test = userdata.filter((data) => data.talentId )
            console.log(test,'Another navigation for users');
           setTalentChat(test[0])
           setUserChat('')
           setModelChat(test[0])
           setnewtalent(true)
          await handleSendMessageUser(test[0]);
        } catch (error) {
          console.log("Error navigating and sending message", error);
        }
      };

    const handleSendMessageTalent = async () => {
        var multipleImage = talentstate2.filter(x => x != null);
        if (multipleImage && multipleImage.length > 0) {
            multipleImage.forEach(async (mediaUri) => {
                const messageType = mediaUri.type;
                const mediaMessageRef = firebase.database().ref('messages').push();
                const imageUrl = await uploadImage(mediaUri)
                mediaMessageRef.set({
                    sender: adminget._id,
                    receiver: talentchat && talentchat.talentId,
                    mediaType: messageType,
                    media: imageUrl,
                    message: '',
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                    status: 'sent',
                    enquiryId:allmessages.enquiryId
                });
                setTalentState1([])
                setTalentState2([])
                const messageBody = {
                    "to": receiverDetails.fcm,
                    "notification": {
                        "body": '',
                        "title": `${userchat && userchat.name} sent an image`,
                        "notificationType": 'Message'
                    },
                }
                fetch('https://fcm.googleapis.com/fcm/send', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
                    },
                    body: JSON.stringify(messageBody),
                }).then((res) => console.log(res, 'notisentresponse')).catch((err) => console.log(err, "notinotsentcatch"))
                console.log(`${messageType} message sent successfully!`);
            })
        }

        if (sendmessagestalent !== '') {
            console.log("Inside the sendmessagestalent",allmessages.enquiryId)
            const messageRef = firebase.database().ref('messages').push();
            messageRef.set({
                sender: adminget._id,
                receiver: talentchat && talentchat.talentId,
                message: `${sendmessagestalent}`,
                mediaType: '',
                media: '',
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                status: 'sent',
                readBy: [userchat && userchat.talentId],
                // enquiryId:allmessages.enquiryId
                enquiryId: "66cea7ef524e207e93cd2e3b"
            });
            setSendMessagestalent('')

            const messageBodytalent = {
                "to": receiverDetails.fcm,
                "notification": {
                    "body": `${sendmessagestalent}`,
                    "title": `${talentchat && talentchat.name} sent an message`,
                    "notificationType": 'Message'
                },
            }
            fetch('https://fcm.googleapis.com/fcm/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'key=AAAAd0lJA7Y:APA91bGijU9rUw18g4bv-QayHnuQ9bKLRUvrB42SoR_LXH6uZKP1EGRai7C3H_TfGA2cso7gouVemt2JTTpz9dzkvxxqXA_F30qMesrGqVlY8ocUdwzKFeILFHKTSm0OmWbtL6U94TCF'
                },
                body: JSON.stringify(messageBodytalent),
            }).then((res) => console.log(res, 'notisentresponse')).catch((err) => console.log(err, "notinotsentcatch"))
            console.log(`message sent successfully!`);
        }
    }


    const NavigateData = (val) => {
         let test = alldetails.filter((data) => data.talentId === val)
         console.log(test,'Navigate talents and models',val);
        setTalentChat(test[0])
        setUserChat('')
        setModelChat(test[0])
        setnewtalent(true)
    }

    const handleMouseDown = (event) => {
        event.preventDefault();
        switch (event.button) {
          case 0:
            console.log('Left mouse button clicked');
            break;
          case 1:
            console.log('Middle mouse button clicked');
            break;
          case 2:
            console.log('Right mouse button clicked');
            break;
          default:
            console.log('Other mouse button clicked');
        }
      };
      const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the context menu from appearing
        console.log('Right mouse button click detected, context menu suppressed.');
      };


    return (

        <React.Fragment>
            <Box component="section" className="content">
                <Box style={{ display: 'flex ', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box className='flex-box mb-10 segmentControl' style={{ justifyContent: 'start' }}>
                        <Tabs
                            value={details}
                            onChange={handleChangeDetails}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                            className='segmentControl'
                            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                        >
                            <Tab className='subsegment' sx={{ textTransform: "none" }} value="User" label="UserS" />
                            <Tab className='subsegment' sx={{ textTransform: "none" }} value="Talent" label="Talent" />
                            <Tab className='subsegment' sx={{ textTransform: "none" }} value="Model" label="Model" />
                            <Tab className='subsegment' sx={{ textTransform: "none" }} value="Studio" label="Studio" />
                        </Tabs>
                    </Box>
                </Box>



                {details === 'User' && newtalent === false && userchat === '' ?
                    <TableContainer component={Paper}>
                        {/* <Typography sx={{ flex: '1 1 100%', margin: 2 }} variant="h6" id="tableTitle" component="div">Users List</Typography> */}
                        <Table sx={{ minWidth: 600, maxWidth: 600 }} aria-label="simple table">
                            <TableBody>


                                {userdata && userdata?.length > 0 && userdata.map((row, i) => {
                                    const firstLetter = 'A';
                                    return <TableRow style={{ cursor: 'pointer' }} onClick={() => ChatMessageUser(row)} key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <Avatar sx={{ width: 32, height: 32, margin: 1 }}>{firstLetter ? firstLetter : 'Y'}</Avatar>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    details === 'User' && userchat !== '' ?
                        <>
                            <Box style={{ border: '1px solid #ccc', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.5)' }} className="background-data">
                                
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={handleBack}><ArrowBackIcon /></Button>
                                    <Avatar sx={{ width: 32, height: 32, margin: 1 }}>A</Avatar>
                                    <Typography sx={{ zIndex: 15, margin: 2 }}>
                                        {userchat && userchat.name}
                                    </Typography>
                                </div>
                                <Box className="chat-container" ref={scrollRef}>
                                    {adminMessage && adminMessage.length > 0 ? adminMessage.map((message, index) => {
                                        console.log(message.receiver,admindetails[0].admin_id ,"messagemessage");

                                        let date = new Date(message.timestamp).toLocaleString();
                                        let formattedDateTime = date.toLocaleString();
                                        let format = convertDateFormat(formattedDateTime);
                                        return (
                                            <Grid container key={index}>
                                                <Grid item xs={12}>
                                                    {message && message.receiver === admindetails[0].AdminId ?
                                                       <Card sx={{ width: "28%", margin: 1, background: "#c4c5cf", borderRadius: '30px', position: 'relative' }} >
                                                       <Typography sx={{ zIndex: 10, margin: 2 }}>
                                                           {userchat && userchat.name}
                                                       </Typography>
                                                       {message && message.enquiryId &&
                                                           <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                                               <Button onClick={() => NavigateData(message.enquiryId)} sx={{ marginBottom: 5, color:'#1d91a8' }}>Enquiry to </Button>
                                                           </Box>
                                                       }
                                                       <CardContent>
                                                           <Typography sx={{ zIndex: 15, margin: -1 }} >
                                                               {message && message.message ? message.message : message.media ? <img src={message.media} style={{ width: '150px', height: 'auto' }} alt="media" /> : ""}
                                                           </Typography>
                                                           <Typography variant="h6" component="h6" sx={{ zIndex: 15, textAlign: 'right' }} >
                                                           { console.log(format,"formatformatformatformat")}
                                                               {format}
                                                           </Typography>
                                                           <Box sx={{  top: 0, right: 0 }}>
                                                               <Button onClick={() => NavigateData(message.enquiryId)} sx={{ marginBottom: 5, color:'#1d91a8' }}>Reply</Button>
                                                           </Box>
                                                       </CardContent>
                                                   </Card>
                                                   
                                                        :
                                                        <Card sm container sx={{ width: "48%", margin: 1, float: "right", justifyContent: "space-around", backgroundColor: "#c1c7e0", borderRadius: '30px' }}>
                                                            <div className="icon-wrapper">
                                                                <Button onClick={() => RemoveMessage(message)}>
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </div>
                                                            <Typography sx={{ zIndex: 15, margin: 2 }} >
                                                                {message && message.sender === admindetails[0].admin_id ? "Admin" : ""}
                                                            </Typography>
                                                            
                                                            <CardContent>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5 }} >
                                                                    {message && message.message ? message.message : message.media && message.mediaType.includes('image') ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img" /> : message.mediaType.includes('video') ? <video src={message.media} controls /> : ""}
                                                                </Typography>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5, textAlign: 'right' }} >
                                                                    {format}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>}
                                                </Grid>
                                            </Grid>
                                        )
                                    }) : ""}
                                </Box>


                                {state1.length > 0 ? state1.map((x, i) => (
                                    <Grid item xs={6} sm={4} md={4} lg={4} key={i}>
                                        <Box className='uploaded_img' >
                                            <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                            {state2[i].type.includes('image') ? <img src={x} alt='' height={155} width={195} /> : <video src={x} controls alt='' hight={160} width={200} ></video>}
                                        </Box>
                                    </Grid>
                                )) : ""}

                                <Box className="form-box mb-10 mt-20 alt-input">
                                    <FormControl style={{ width: "95%", marginLeft: 15, marginBottom: 20 }}>
                                        <OutlinedInput
                                            ref={inputRef}
                                            style={{ paddingRight: 0 }}
                                            id="standard-adornment-password"
                                            placeholder='Message'
                                            value={sendmessages}
                                            onKeyPress={_handleKeyDown}
                                            onChange={(e) => setSendMessages(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end" className='end-adornments adornments'>
                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <label htmlFor="contained-button-file1" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                    onChange={multipleImgChange}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file1"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><AttachFileIcon /></Box>
                                                            </Box>
                                                        </label>

                                                        <label htmlFor="contained-button-file2" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "video/mp4" }}
                                                                    onChange={handleVideoUpload}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file2"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon /></Box>
                                                            </Box>
                                                        </label>
                                                        <Button onClick={handleSendMessage} className="msgSendBtn"><Send /></Button>
                                                    </Box>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </>
                        : ""
                }

                {details === 'Talent' && talentchat === '' ?
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600, maxWidth: 600 }} aria-label="simple table">
                            <TableBody>
                                {talentdata && talentdata?.length > 0 && talentdata.map((row, i) => {
                                    const firstLetter = 'A'
                                    return <> <TableRow style={{ cursor: 'pointer' }} onClick={() => ChatMessageTalent(row)} key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <Avatar sx={{ width: 32, height: 32, margin: 1 }}>{firstLetter ? firstLetter : 'Y'}</Avatar>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                    </TableRow>
                                    </>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    details === 'Talent' && talentchat !== '' ?
                        <>
                            <Box style={{ border: '1px solid #ccc', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)' }} className="background-data">
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={handleBackTalent}><ArrowBackIcon /></Button>
                                    <Avatar sx={{ width: 32, height: 32, margin: 1 }}></Avatar>
                                    <Typography sx={{ zIndex: 15, margin: 2 }}>
                                        {talentchat && talentchat.name}
                                    </Typography>
                                </div>
                                <Box className="chat-container" ref={scrollRef}>
                                    {talentMessage && talentMessage.length > 0 ? talentMessage.map((message, index) => {
                                        console.log(message, 'message Talent');
                                        let date = new Date(message.timestamp).toLocaleString();
                                        let formattedDateTime = date.toLocaleString();
                                        let format = convertDateFormat(formattedDateTime);
                                        return (
                                            <Grid container key={index}>
                                                <Grid item xs={12}>
                                                    {message && message.receiver === admindetails[0].admin_id ?
                                                       <Card sx={{ width: "48%", margin: 1, background: "#fff", borderRadius: '30px', position: 'relative' }}>
                                                       <Typography sx={{ zIndex: 15, margin: 2 }}>
                                                           {talentchat && talentchat.name}
                                                       </Typography>

                                                      
                                                       <CardContent>
                                                           <Typography sx={{ zIndex: 15, margin: -1 }} >
                                                               {message && message.message ? message.message : message.media ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img" /> : ""}
                                                           </Typography>
                                                           <Typography variant="h6" component="h6" sx={{ zIndex: 15, textAlign: 'right' }} >
                                                               {format}
                                                           </Typography>
                                                       </CardContent>
                                                   </Card>
                                                   
                                                        :
                                                        <Card sm container sx={{ width: "48%", margin: 1, float: "right", justifyContent: "space-around", backgroundColor: "#c1c7e0", borderRadius: '30px' }}>
                                                            <div className="icon-wrapper">
                                                                <Button onClick={() => RemoveMessage(message)}>
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </div>
                                                            <Typography sx={{ zIndex: 15, margin: 2 }} >
                                                                {message && message.sender === admindetails[0].admin_id ? "Admin" : ""}
                                                            </Typography>
                                                            <CardContent>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5 }} >
                                                                    {message && message.message ? message.message : message.media && message.mediaType.includes('image') ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img"/> : message.mediaType.includes('video') ? <video src={message.media} controls /> : ""}
                                                                </Typography>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5, textAlign: 'right' }} >
                                                                    {format}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>}
                                                </Grid>
                                            </Grid>
                                        )
                                    }) : ""}
                                </Box>


                                {/* {talentstate1.length > 0 ? talentstate1.map((x, i) => (
                                    <Grid item xs={6} sm={4} md={4} lg={4} key={i}>
                                        <Box className='uploaded_img' >
                                            <Button className='close_icon' onClick={(e) => indexDeleteTalent(e, i)}><Close /></Button>
                                            {talentstate2[i].type.includes('image') ? <img src={x} alt='' height={155} width={195} /> : <video src={x} controls alt='' hight={160} width={200} ></video>}
                                        </Box>
                                    </Grid>
                                )) : ""} */}

                                {talentstate1.length > 0 ? (
                                    talentstate1.map((x, i) => (
                                        <Grid item xs={6} sm={4} md={4} lg={4} key={i}>
                                            <Box className='uploaded_img'>
                                                <Button className='close_icon' onClick={(e) => indexDeleteTalent(e, i)}>
                                                    <Close />
                                                </Button>
                                                {talentstate2[i]?.type?.includes('image') ? (
                                                    <img src={x} alt='' height={155} width={195} />
                                                ) : (
                                                    <video src={x} controls alt='' height={160} width={200} />
                                                )}
                                            </Box>
                                        </Grid>
                                    ))
                                ) : null}

                                <Box className="form-box mb-10 mt-20 alt-input">
                                    <FormControl style={{ width: "95%", marginLeft: 15, marginBottom: 20 }}>
                                        <OutlinedInput
                                            ref={inputRef}
                                            style={{ paddingRight: 0 }}
                                            id="standard-adornment-password"
                                            placeholder='Message'
                                            value={sendmessagestalent}
                                            onKeyPress={_handleKeyDownTalent}
                                            onChange={(e) => setSendMessagestalent(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end" className='end-adornments adornments'>
                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <label htmlFor="contained-button-file1" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                    onChange={TalentImageChange}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file1"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><AttachFileIcon /></Box>
                                                            </Box>
                                                        </label>

                                                        <label htmlFor="contained-button-file2" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "video/mp4" }}
                                                                    onChange={handleVideoUploadTalent}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file2"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon /></Box>
                                                            </Box>
                                                        </label>
                                                        <Button onClick={handleSendMessageTalent} className="msgSendBtn"><Send /></Button>
                                                    </Box>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </>
                        : ""
                }

                {details === "Model" && modelchat === '' ?
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600, maxWidth: 600 }} aria-label="simple table">
                            <TableBody>
                                {modeldata && modeldata?.length > 0 && modeldata.map((row, i) => {
                                    const firstLetter = 'A'
                                    return <> <TableRow style={{ cursor: 'pointer' }} onClick={() => ChatMessagemodel(row)} key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <Avatar sx={{ width: 32, height: 32, margin: 1 }}>{firstLetter ? firstLetter : 'Y'}</Avatar>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                    </TableRow>
                                    </>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    details === "Model" && modelchat !== '' ?
                        <>
                            <Box style={{ border: '1px solid #ccc', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)' }} className="background-data">
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={handleBackTalent}><ArrowBackIcon /></Button>
                                    <Avatar sx={{ width: 32, height: 32, margin: 1 }}></Avatar>
                                    <Typography sx={{ zIndex: 15, margin: 2 }}>
                                        {modelchat && modelchat.name}
                                    </Typography>
                                </div>
                                <Box className="chat-container" ref={scrollRef}>
                                    {modelMessage && modelMessage.length > 0 ? modelMessage.map((message, index) => {
                                        
                                        let date = new Date(message.timestamp).toLocaleString();
                                        let formattedDateTime = date.toLocaleString();
                                        let format = convertDateFormat(formattedDateTime);
                                        return (
                                            <Grid container key={index}>
                                                <Grid item xs={12}>
                                                    {message && message.receiver === admindetails[0].admin_id ?
                                                       <Card sx={{ width: "48%", margin: 1, background: "#fff", borderRadius: '30px', position: 'relative' }}>
                                                       <Typography sx={{ zIndex: 15, margin: 2 }}>
                                                           {modelchat && modelchat.name}
                                                       </Typography>

                                                      
                                                       <CardContent>
                                                           <Typography sx={{ zIndex: 15, margin: -1 }} >
                                                               {message && message.message ? message.message : message.media ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img"/> : ""}
                                                           </Typography>
                                                           <Typography variant="h6" component="h6" sx={{ zIndex: 15, textAlign: 'right' }} >
                                                               {format}
                                                           </Typography>
                                                       </CardContent>
                                                   </Card>
                                                   
                                                        :
                                                        <Card sm container sx={{ width: "48%", margin: 1, float: "right", justifyContent: "space-around", backgroundColor: "#c1c7e0", borderRadius: '30px' }}>
                                                            <div className="icon-wrapper">
                                                                <Button onClick={() => RemoveMessage(message)}>
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </div>
                                                            <Typography sx={{ zIndex: 15, margin: 2 }} >
                                                                {message && message.sender === admindetails[0].admin_id ? "Admin" : ""}
                                                            </Typography>
                                                            <CardContent>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5 }} >
                                                                    {message && message.message ? message.message : message.media && message.mediaType.includes('image') ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img" /> : message.mediaType.includes('video') ? <video src={message.media} controls /> : ""}
                                                                </Typography>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5, textAlign: 'right' }} >
                                                                    {format}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>}
                                                </Grid>
                                            </Grid>
                                        )
                                    }) : ""}
                                </Box>


                                {talentstate1.length > 0 ? talentstate1.map((x, i) => (
                                    <Grid item xs={6} sm={4} md={4} lg={4} key={i}>
                                        <Box className='uploaded_img' >
                                            <Button className='close_icon' onClick={(e) => indexDeleteTalent(e, i)}><Close /></Button>
                                            {talentstate2[i].type.includes('image') ? <img src={x} alt='' height={155} width={195} /> : <video src={x} controls alt='' hight={160} width={200} ></video>}
                                        </Box>
                                    </Grid>
                                )) : ""}

                                <Box className="form-box mb-10 mt-20 alt-input">
                                    <FormControl style={{ width: "95%", marginLeft: 15, marginBottom: 20 }}>
                                        <OutlinedInput
                                            ref={inputRef}
                                            style={{ paddingRight: 0 }}
                                            id="standard-adornment-password"
                                            placeholder='Message'
                                            value={sendmessagestalent}
                                            onKeyPress={_handleKeyDownTalent}
                                            onChange={(e) => setSendMessagestalent(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end" className='end-adornments adornments'>
                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <label htmlFor="contained-button-file1" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                    onChange={TalentImageChange}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file1"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><AttachFileIcon /></Box>
                                                            </Box>
                                                        </label>

                                                        <label htmlFor="contained-button-file2" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "video/mp4" }}
                                                                    onChange={handleVideoUploadTalent}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file2"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon /></Box>
                                                            </Box>
                                                        </label>
                                                        <Button onClick={handleSendMessageTalent} className="msgSendBtn"><Send /></Button>
                                                    </Box>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </>
                        : ""
                }

                {details === "Studio" && studiochat === '' ?
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600, maxWidth: 600 }} aria-label="simple table">
                            <TableBody>
                                {studiodata && studiodata?.length > 0 && studiodata.map((row, i) => {
                                    const firstLetter = 'A'
                                    return <> <TableRow style={{ cursor: 'pointer' }} onClick={() => ChatMessagestudio(row)} key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <Avatar sx={{ width: 32, height: 32, margin: 1 }}>{firstLetter ? firstLetter : 'Y'}</Avatar>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                    </TableRow>
                                    </>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    details === "Studio" && studiochat !== '' ?
                        <>
                            <Box style={{ border: '1px solid #ccc', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)' }} className="background-data">
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={handleBackTalent}><ArrowBackIcon /></Button>
                                    <Avatar sx={{ width: 32, height: 32, margin: 1 }}></Avatar>
                                    <Typography sx={{ zIndex: 15, margin: 2 }}>
                                        {studiochat && studiochat.name}
                                    </Typography>
                                </div>
                                <Box className="chat-container" ref={scrollRef}>
                                    {modelMessage && modelMessage.length > 0 ? modelMessage.map((message, index) => {
                                        
                                        let date = new Date(message.timestamp).toLocaleString();
                                        let formattedDateTime = date.toLocaleString();
                                        let format = convertDateFormat(formattedDateTime);
                                        return (
                                            <Grid container key={index}>
                                                <Grid item xs={12}>
                                                    {message && message.receiver === admindetails[0].AdminId ?
                                                       <Card sx={{ width: "48%", margin: 1, background: "#fff", borderRadius: '30px', position: 'relative' }}>
                                                       <Typography sx={{ zIndex: 15, margin: 2 }}>
                                                           {studiochat && studiochat.name}
                                                       </Typography>

                                                      
                                                       <CardContent>
                                                           <Typography sx={{ zIndex: 15, margin: -1 }} >
                                                               {message && message.message ? message.message : message.media ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img" /> : ""}
                                                           </Typography>
                                                           <Typography variant="h6" component="h6" sx={{ zIndex: 15, textAlign: 'right' }} >
                                                               {format}
                                                           </Typography>
                                                       </CardContent>
                                                   </Card>
                                                   
                                                        :
                                                        <Card sm container sx={{ width: "48%", margin: 1, float: "right", justifyContent: "space-around", backgroundColor: "#c1c7e0", borderRadius: '30px' }}>
                                                            <div className="icon-wrapper">
                                                                <Button onClick={() => RemoveMessage(message)}>
                                                                    <DeleteIcon />
                                                                </Button>
                                                            </div>
                                                            <Typography sx={{ zIndex: 15, margin: 2 }} >
                                                                {message && message.sender === admindetails[0].admin_id ? "Admin" : ""}
                                                            </Typography>
                                                            <CardContent>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5 }} >
                                                                    {message && message.message ? message.message : message.media && message.mediaType.includes('image') ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img" /> : message.mediaType.includes('video') ? <video src={message.media} controls /> : ""}
                                                                </Typography>
                                                                <Typography sx={{ zIndex: 15, margin: -0.5, textAlign: 'right' }} >
                                                                    {format}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>}
                                                </Grid>
                                            </Grid>
                                        )
                                    }) : ""}
                                </Box>


                                {talentstate1.length > 0 ? talentstate1.map((x, i) => (
                                    <Grid item xs={6} sm={4} md={4} lg={4} key={i}>
                                        <Box className='uploaded_img' >
                                            <Button className='close_icon' onClick={(e) => indexDeleteTalent(e, i)}><Close /></Button>
                                            {talentstate2[i].type.includes('image') ? <img src={x} alt='' height={155} width={195} /> : <video src={x} controls alt='' hight={160} width={200} ></video>}
                                        </Box>
                                    </Grid>
                                )) : ""}

                                <Box className="form-box mb-10 mt-20 alt-input">
                                    <FormControl style={{ width: "95%", marginLeft: 15, marginBottom: 20 }}>
                                        <OutlinedInput
                                            ref={inputRef}
                                            style={{ paddingRight: 0 }}
                                            id="standard-adornment-password"
                                            placeholder='Message'
                                            value={sendmessagestalent}
                                            onKeyPress={_handleKeyDownTalent}
                                            onChange={(e) => setSendMessagestalent(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end" className='end-adornments adornments'>
                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <label htmlFor="contained-button-file1" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                    onChange={TalentImageChange}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file1"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><AttachFileIcon /></Box>
                                                            </Box>
                                                        </label>

                                                        <label htmlFor="contained-button-file2" className="w-full" style={{ marginLeft: 10 }}>
                                                            <Box className='multiple_upload_btn18' >
                                                                <Input
                                                                    inputProps={{ accept: "video/mp4" }}
                                                                    onChange={handleVideoUploadTalent}
                                                                    style={{ display: "none", }}
                                                                    id="contained-button-file2"
                                                                    type="file"
                                                                />
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon /></Box>
                                                            </Box>
                                                        </label>
                                                        <Button onClick={handleSendMessageTalent} className="msgSendBtn"><Send /></Button>
                                                    </Box>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </>
                        : ""
                }
            </Box>





            {newtalent === true &&
              <>
              <Box style={{ border: '1px solid #ccc', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)' }} className="background-data">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button onClick={handleBackUser}><ArrowBackIcon /></Button>
                      <Avatar sx={{ width: 32, height: 32, margin: 1 }}>{talentchat && talentchat.name.charAt(0).toUpperCase()}</Avatar>
                      <Typography sx={{ zIndex: 15, margin: 2 }}>
                          {talentchat && talentchat.name}
                      </Typography>
                  </div>
                  <Box className="chat-container" ref={scrollRef}>
                      {talentMessage && talentMessage.length > 0 ? talentMessage.map((message, index) => {
                          console.log(message, 'message Talent');
                          let date = new Date(message.timestamp).toLocaleString();
                          let formattedDateTime = date.toLocaleString();
                          let format = convertDateFormat(formattedDateTime);
                          return (
                              <Grid container key={index}>
                                  <Grid item xs={12}>
                                      {message && message.receiver === admindetails[0].AdminId ?
                                         <Card sx={{ width: "28%", margin: 1, background: "#c4c5cf", borderRadius: '30px', position: 'relative' }}>
                                         <Typography sx={{ zIndex: 15, margin: 2 }}>
                                             {talentchat && talentchat.name}
                                         </Typography>

                                         {message && message.enquiryId &&
                                                           <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                                               <Button onClick={() => NavigateData1(message.enquiryId)} sx={{ marginBottom: 5, color:'#1d91a8' }}>Send to </Button>
                                                           </Box>
                                                       }
                                         <CardContent>
                                             <Typography sx={{ zIndex: 15, margin: -1 }} >
                                                 {message && message.message ? message.message : message.media ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img" /> : ""}
                                             </Typography>
                                             <Typography variant="h6" component="h6" sx={{ zIndex: 15, textAlign: 'right' }} >
                                                 {format}
                                             </Typography>
                                         </CardContent>
                                     </Card>
                                     
                                          :
                                          <Card sm container sx={{ width: "48%", margin: 1, float: "right", justifyContent: "space-around", backgroundColor: "#c1c7e0", borderRadius: '30px' }}>
                                              <div className="icon-wrapper">
                                                  <Button onClick={() => RemoveMessage(message)}>
                                                      <DeleteIcon />
                                                  </Button>
                                              </div>
                                              <Typography sx={{ zIndex: 15, margin: 2 }} >
                                                  {message && message.sender === admindetails[0].admin_id ? "Admin" : ""}
                                              </Typography>
                                              <CardContent>
                                                  <Typography sx={{ zIndex: 15, margin: -0.5 }} >
                                                      {message && message.message ? message.message : message.media && message.mediaType.includes('image') ? <img src={message.media} style={{ width: '200px', height: 'auto' }} alt="img" /> : message.mediaType.includes('video') ? <video src={message.media} controls /> : ""}
                                                  </Typography>
                                                  <Typography sx={{ zIndex: 15, margin: -0.5, textAlign: 'right' }} >
                                                      {format}
                                                  </Typography>
                                              </CardContent>
                                          </Card>}
                                  </Grid>
                              </Grid>
                          )
                      }) : ""}
                  </Box>


                  {talentstate1.length > 0 ? talentstate1.map((x, i) => (
                      <Grid item xs={6} sm={4} md={4} lg={4} key={i}>
                          <Box className='uploaded_img' >
                              <Button className='close_icon' onClick={(e) => indexDeleteTalent(e, i)}><Close /></Button>
                              {talentstate2[i].type.includes('image') ? <img src={x} alt='' height={155} width={195} /> : <video src={x} controls alt='' hight={160} width={200} ></video>}
                          </Box>
                      </Grid>
                  )) : ""}

                  <Box className="form-box mb-10 mt-20 alt-input">
                      <FormControl style={{ width: "95%", marginLeft: 15, marginBottom: 20 }}>
                          <OutlinedInput
                              ref={inputRef}
                              style={{ paddingRight: 0 }}
                              id="standard-adornment-password"
                              placeholder='Message'
                              value={sendmessagestalent}
                              onKeyPress={_handleKeyDownTalent}
                              onChange={(e) => setSendMessagestalent(e.target.value)}
                              endAdornment={
                                  <InputAdornment position="end" className='end-adornments adornments'>
                                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                                          <label htmlFor="contained-button-file1" className="w-full" style={{ marginLeft: 10 }}>
                                              <Box className='multiple_upload_btn18' >
                                                  <Input
                                                      inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                      onChange={TalentImageChange}
                                                      style={{ display: "none", }}
                                                      id="contained-button-file1"
                                                      type="file"
                                                  />
                                                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><AttachFileIcon /></Box>
                                              </Box>
                                          </label>

                                          <label htmlFor="contained-button-file2" className="w-full" style={{ marginLeft: 10 }}>
                                              <Box className='multiple_upload_btn18' >
                                                  <Input
                                                      inputProps={{ accept: "video/mp4" }}
                                                      onChange={handleVideoUploadTalent}
                                                      style={{ display: "none", }}
                                                      id="contained-button-file2"
                                                      type="file"
                                                  />
                                                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon /></Box>
                                              </Box>
                                          </label>
                                          <Button onClick={handleSendMessageTalent} className="msgSendBtn"><Send /></Button>
                                      </Box>
                                  </InputAdornment>
                              }
                          />
                      </FormControl>
                  </Box>
              </Box>
          </>}
            

            <Dialog open={open} keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'>
                <DialogTitle>Would you like to delete Message?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={() => RemoveMessageNew(deleteid)} >Yes</Button>

                    {/* <Button  style={{color:"red"}} class="theme-btn">Yes</Button> */}
                </DialogActions>
            </Dialog>

        </React.Fragment >
    )
};

export default Chat;