import * as React from 'react';
import { Box, Typography, Grid, Button, InputAdornment, MenuItem, Select, TextField, Snackbar, Avatar, IconButton, FormControl } from '@mui/material';
import { AccountCircleOutlined, KeyRounded, Visibility, VisibilityOff, AddAPhoto } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import MiniDrawer from '../../../components/drawer'
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { FaSpinner } from 'react-icons/fa';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Input = styled('input')({
    display: 'none',
});


const Profile = () => {
    const auth = JSON.parse(localStorage.getItem("userData"))
    console.log(auth,"Admin details in userdata")
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    React.useEffect(() => {
        getprofiledataapi()

    }, [auth && auth?.company_id])

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;
    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [profileedit, setProfileedit] = React.useState([])
    const [firstname, setfirstname] = React.useState(auth.name ? auth.name : "")
    const [lastname, setlastname] = React.useState(auth.lastname ? auth.lastname : "")
    const [state1, setState1] = React.useState('')
    const [email, Setemail] = React.useState('')
    const [zip, setzip] = React.useState('')
    const [address1, setAddress1] = React.useState('')
    const [address2, setAddress2] = React.useState('')
    const [city, Setcity] = React.useState('')
    const [mobile, setMobile] = React.useState('')
    const [fax, setFax] = React.useState('')
    const [country, setCountry] = React.useState('')
    const [corporateid, setCorporateid] = React.useState('')
    const [image, setImage] = React.useState('')
    const [prvImage, setPrvImage] = React.useState('')
    const [password, Setpassword] = React.useState({ newpassword: "", confirmPassword: "" })
    const [profiledata, setProfiledata] = React.useState([])
    const Permission = JSON.parse(localStorage.getItem("Permissions"))
    const [EnableEmail, setEnableEmail] = React.useState(false)
    const [state2, setState2] = React.useState([])

    const [error, setError] = React.useState({
        newpassword: "", confirmPassword: "", name:"", lastname:""
    })

    const validation = () => {
        if (!firstname) {
            setError({ name: "This is a required filed" })
            enqueueSnackbar("First name can not be empty", { variant: 'error' })
            return false
        }

        return true
    }
    // const handleprofilephoto = (e, index) => {
    //     const file = e.target.files[0];
    //     console.log(file,"ttttttttyyyyyyyyyyy")
	// 	setProfileedit(file)
    //     if (!file) {
    //         return; 
    //       }
        
	// }

    
    // const handleprofilephoto = (e) => {
    //     // setProfiledata({ Imagepreview: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    //     if (e.target.files.length > 0) {
    //         setProfileedit(prev => ({
    //             ...prev,
    //             Imagepreview: URL.createObjectURL(e.target.files[0]),
    //             Imageraw: e.target.files[0]
    //         }));
    //     }
    // }
    const getprofiledataapi = async () => {
        axios.get(process.env.REACT_APP_BASE_URL + `adminpart/getAdmindetails/${auth && auth._id}`,).then((res) => {
            console.log(res.data, 'rsesgsgs');
            setfirstname(res.data.admindata.name)
            Setemail(res.data.admindata.email)
            setzip(res.data.admindata.zipcode ? res.data.admindata.zipcode : "")
            Setcity(res.data.admindata.city ? res.data.admindata.city : "")
            setAddress1(res.data.admindata.streetaddress1 ? res.data.admindata.streetaddress1 : '')
            setAddress2(res.data.admindata.streetaddress2 ? res.data.admindata.streetaddress2 : '')
            setMobile(res.data.admindata.mobile ? res.data.admindata.mobile : '')
            setState1(res.data.admindata.state ? res.data.admindata.state : '')
            setPrvImage(res.data.admindata.profile ? res.data.admindata.profile : '')
            setFax(res.data.admindata.fax ? res.data.admindata.fax : "")
            setCountry(res.data.admindata.country)
            setCorporateid(res.data.admindata.corporateid)
            setEnableEmail(res.data.admindata.EnableEmail ? res.data.admindata.EnableEmail : false)
        }).catch((err) => console.log(err))
    }
    const [loading, setLoading] = React.useState(false);
    const handleprofilephoto = (e) => {
        const file = e.target.files[0];
        setProfileedit(file)
        if (file) {
          setLoading(true);
          // Simulating a file upload or image processing
          const reader = new FileReader();
          reader.onloadend = () => {
            setImage(reader.result); // Save image as base64 or URL
            setLoading(false);
          };
          reader.readAsDataURL(file); // Read file as Data URL
        }
      };

    const headerformdata = {
        'Content-Type': 'multipart/form-data',
    }

    const handleClick = (newState) => () => {

        if(validation()){
            const formData = new FormData();
        if (profileedit) {
            formData.append("profile", profileedit);
          }
        
        formData.append("name",firstname);
        formData.append('lastname',lastname)
        console.log([...formData])
        console.log(auth._id)
      
        axios.put(process.env.REACT_APP_BASE_URL + `adminpart/updateadmin/${auth._id}`, formData,{ headers: headerformdata })
        .then((res) => {
            localStorage.setItem("userData", JSON.stringify(res.data.updateAdmin))
            navigate("/dashboard")       
            enqueueSnackbar("Profile updated successfully", { variant: 'success' })
        }).catch((err) => console.log(err))
        } else{
            return;
        }
        
    };


    let name2, value2;
    const Handlepassword = (e) => {
        name2 = e.target.name;
        value2 = e.target.value;
        Setpassword({ ...password, [name2]: value2 })
        setError({ ...error, [name2]: '' })
    }


    const Validator = () => {
        if (!password.newpassword && !password.confirmPassword) {
            setError({ ...error, newpassword: "Password cannot be empty", confirmPassword: "Confirm password cannot be empty" })
            return false
        }
        else if (!password.newpassword) {
            setError({ ...error, newpassword: "Password cannot be empty" })
            return false
        }

        else if (!password.confirmPassword) {
            setError({ ...error, confirmPassword: "Confirm password cannot be empty" })
            return false
        }
        if (password.newpassword !== password.confirmPassword) {
            setError({ confirmPassword: "Password miss-match" })
            return false
        }
        // else {
        //     setError({ newpassword: "", confirmPassword: "" })
        // }
        return true
    }


    const handlepasswordClick = (newState) => () => {
        const validate = Validator();
        if (validate === true) {
            var body = {
                newPassword: password.newpassword,
                confirmPassword:password.confirmPassword,
                email:email
            }
            axios.post(process.env.REACT_APP_BASE_URL + `adminpart/adminchangepassword`, body,).then((res) => {
                console.log(res, "sasasa");
                getprofiledataapi();
                enqueueSnackbar("Profile updated successfully", { variant: 'success' })
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const [view, setView] = React.useState(false);
    const passwordview = () => {
        setView(!view)
    }
    const [view1, setView1] = React.useState(false);
    const passwordview1 = () => {
        setView1(!view1)
    }
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };


    console.log(mobile, "mobilemobile");
    return (
        <React.Fragment>
            <Box className='Addcompany'>
                <MiniDrawer />
                <Box component="section" className="contentWraper">
                    <Box className='headingBox'>
                        <Typography  component="h4" variant="h4" className='page-heading'>Profile</Typography>
                    </Box>
                    <Box component="section" className="profileSection">
                        <Box className='profile-details'>
                            <Box className='tabs-div'>
                                <Tabs className="tabsHeading" value={value} onChange={handleChange} aria-label="profile tabs">
                                    <Tab icon={<AccountCircleOutlined />} iconPosition="start" label="Profile" {...a11yProps(0)} />
                                    <Tab icon={<KeyRounded />} iconPosition="start" label="Change Password" {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                            <TabPanel value={value} index={0}>
                                <Grid container spacing={3}>

                                    <Grid item sm={12} md={5} lg={4} className='w100'>
                                        <Box className='personal-info-tab'>
                                            <Typography component='h2' className='profile-heading' >
                                                Profile Photo
                                            </Typography>
                                            {/* <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" type="file" onChange={handleprofilephoto} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                       
                                                        {profileedit ? <Avatar src={profileedit}>
                                                        </Avatar> : image ? <React.Suspense fallback={<FaSpinner className="spin" />}>
                                                            <Avatar src={process.env.REACT_APP_BASE_URL + image}> </Avatar>
                                                        </React.Suspense> : <Avatar size={30} />
                                                        }

                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                                
                                            </Box> */}
                                            <Box className='profile-photo'>
                                            <label htmlFor="icon-button-file">
                                                <Input 
                                                accept="image/*" 
                                                id="icon-button-file" 
                                                type="file" 
                                                onChange={handleprofilephoto}
                                                style={{ display: 'none' }} // Hide the file input
                                                />
                                                <IconButton 
                                                color="primary" 
                                                aria-label="upload picture" 
                                                component="span"
                                                >
                                                    {console.log(profileedit)}
                                                {/* If there's an image and it's loading, show a spinner */}
                                                {loading ? (
                                                    <FaSpinner className="spin" />
                                                ) : (
                                                    <Avatar 
                                                    src={image ? image : process.env.REACT_APP_BASE_URL + `${prvImage}`} 
                                                    sx={{ width: 150, height: 150 }} 
                                                    />
                                                )}
                                                <AddAPhoto />
                                                </IconButton>
                                            </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 2 MB
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item sm={12} md={7} lg={8}>
                                        <Box className='personal-info-tab'>
                                            <Box component="form" noValidate autoComplete="off">

                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Typography component='div' className='label'>First Name <Typography component='span' className='star'>*</Typography></Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                className='textfield'
                                                                placeholder='Name'
                                                                name="firstname"
                                                                value={firstname ? firstname : ''}
                                                                onChange={(e) => {setfirstname(e.target.value);setError({name:""})}}
                                                            />
                                                           {error.name && <p className="error-message"  style={{color:"red"}}>{error.name}</p>} 
                                                        </FormControl>
                                                    </Grid>
                                                
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Typography component='div' className='label'>Last name </Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                className='textfield'
                                                                placeholder='Last name'
                                                                value={lastname ? lastname : ''}
                                                                name="lastname"
                                                                onChange={(e) => {setlastname(e.target.value);setError({lastname:""})}}

                                                            />
                                                           
                                                        </FormControl>
                                                    </Grid>
                                                   
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" fullWidth>

                                                            <Typography component='div' className='label'>Email</Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                className='textfield'
                                                                placeholder='Email'
                                                                value={email}
                                                                disabled
                                                            />

                                                        </FormControl>
                                                    </Grid>
                                                    {/* <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Typography component='div' className='label'>Mobile</Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                className='textfield'
                                                                placeholder='Mobile'
                                                                name="mobile"
                                                                value={mobile && mobile == "undefined" ? "" : mobile ? mobile : ""}
                                                                onInput={(e) => onlyNumbers(e)}
                                                                inputProps={{ maxLength: 10 }}
                                                                onChange={(e) => setMobile(e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </Grid> */}
                                                 
                                                    
                                                    {/* <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Typography component='div' className='label'>City</Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                className='textfield'
                                                                placeholder='City'
                                                                name="city"
                                                                value={city && city == "undefined" ? '' : city ? city : ""}
                                                                onChange={(e) => Setcity(e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </Grid> */}
                                                    {/* <Grid item xs={12} sm={6}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Typography component='div' className='label'>State:</Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                className='textfield'
                                                                placeholder='State'
                                                                name="state"
                                                                value={state1 && state1 == "undefined" ? '' : state1 ? state1 : ''}
                                                                onChange={(e) => setState1(e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </Grid> */}
                                                   
                                                    {/* <Grid item xs={12} sm={6}>
                                                        <FormControl fullWidth>
                                                            <Typography component='div' className='label'>Country</Typography>
                                                            <Select
                                                                labelId="demo-select-small"
                                                                id="demo-select-small"
                                                                name='country'
                                                                value={country}
                                                                onChange={(e) => setCountry(e.target.value)}
                                                                className='select'
                                                                placeholder='Country'
                                                                MenuProps={MenuProps}
                                                            >
                                                                {countrylist ? countrylist.map((item, i) => (
                                                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                                                )) : ""}
                                                            </Select>
                                                            {error.country && <span style={{ color: "red", fontSize: 12 }}>{error.country}</span>}
                                                        </FormControl>

                                                    </Grid> */}
                                                    
                                                    <Grid item xs={12} sm={6}>
                                                        {console.log(Permission,"PermissionPermission")}
                                                        <FormControl variant="standard" fullWidth>
                                                            {auth && auth.role_id === 3 && Permission.receive_complaint &&
                                                                <>
                                                                    <Typography component='div' className='label'>Enable Email:</Typography>
                                                                    <Switch
                                                                        checked={EnableEmail}
                                                                        onChange={(e) => setEnableEmail(!EnableEmail)}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                    />
                                                                </>
                                                            }
                                                        </FormControl>
                                                    </Grid>

                                                  
                                                </Grid>
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Button className="addbtn" variant="contained" sx={{ mt: 5 }} onClick={handleClick({ vertical: 'top', horizontal: 'center', })} style={{color:'white', backgroundColor:'black'}}>Save Changes</Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            {/* Chnage password */}
                            <TabPanel value={value} index={1}>
                                <Box className='change-password-tab' sx={{ maxWidth: 'sm' }}>
                                    <Box component='form'>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField type={view ? 'text' : 'password'} fullWidth required label="New Password" name="newpassword" onChange={Handlepassword} size="small" helperText={<div style={{ color: "red" }}>{error.newpassword}</div>} InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" onClick={passwordview} sx={{
                                                            cursor: "pointer"
                                                        }} >
                                                            {view ? <VisibilityOff /> : <Visibility />}
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField id="standard-error-helper-text" type={view1 ? 'text' : 'password'} fullWidth required label="Confirm New Password" name="confirmPassword" onChange={Handlepassword} size="small" helperText={<div style={{ color: "red" }}>{error.confirmPassword}</div>}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" onClick={passwordview1} sx={{
                                                                cursor: "pointer"
                                                            }} >
                                                                {view1 ? <VisibilityOff /> : <Visibility />}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                        <Box sx={{ textAlign: 'right' }}>
                                            <Button className="addbtn" variant="contained" sx={{ mt: 2 }} onClick={handlepasswordClick({ vertical: 'top', horizontal: 'center', })} style={{color:'white', backgroundColor:'black'}}>Save Changes</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </Box>


                        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                This is a success message!
                            </Alert>
                        </Snackbar>

                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default Profile;