import MiniDrawer from '../../../components/drawer'
import ReactEcharts from "echarts-for-react";
import React from 'react'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Card, CardContent, CardMedia } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment'


// const BookingChart = {
//     tooltip: {
//         trigger: 'axis'
//     },
//     grid: {
//         left: '3%',
//         right: '4%',
//         bottom: '3%',
//         containLabel: true
//     },
//     xAxis: {
//         type: 'category',
//         data: [   'Jan', 'Feb','Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug','Sep','Oct','Nov','Dec'],
//         boundaryGap: false,
//     },
//     yAxis: {
//         type: 'value',
//         splitNumber: 10,

//     },

//     series: [
//         {
//             data: mounth.count,
//             label: {
//                 show: true,
//                 position: 'top'
//             },
//             type: 'line',
//             smooth: true,
//             // areaStyle: {
//             //     opacity: 0.8,
//             //     // color: new ReactEcharts.graphic.LinearGradient(0, 0, 0, 1, [
//             //     //     {
//             //     //         offset: 0,
//             //     //         color: '#0258FF'
//             //     //     },
//             //     //     {
//             //     //         offset: 0.5,
//             //     //         color: '#CCDEFF'
//             //     //     },
//             //     //     {
//             //     //         offset: 1,
//             //     //         color: '#fff'
//             //     //     }
//             //     // ])
//             //     // color: ["#942e38", "#aaa", "#269f3c"]

//             // },
//         }
//     ]

// };




const complainsTypeOptions = {
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
        left: 'center',
        top: 'bottom',
        icon: 'pin',
        textStyle: {
            fontFamily: 'Nunito',
            lineHeight: 24,
        },
        "formatter": (name) => { return name + '   ' },
    },
    series: [
        {
            name: 'Complains Type',
            type: 'pie',
            radius: [20, 140],
            center: ['50%', '50%'],
            roseType: 'radius',
            itemStyle: {
                borderRadius: 5
            },
            color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00',],
            label: {
                show: true,
                "formatter": (params) => params.value,
                textStyle: {
                    fontFamily: 'Nunito',
                }
            },
            emphasis: {
                label: {
                    show: true
                }
            },
            data: [
                { value: 400, name: 'Verbal' },
                { value: 85, name: 'Abusive' },
                { value: 15, name: 'Sexual' },
                { value: 250, name: 'Harassment' },
                { value: 310, name: 'Disrespectful' },
                { value: 130, name: 'Others' }
            ].sort(function (a, b) {
                return a.value - b.value;
            }),
        },
    ]
};

const CustomerChart = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        top: 'center',
        right: '20',
        orient: 'vertical',
        icon: 'circle',
        textStyle: {
            fontWeight: 700,
            fontFamily: 'Nunito',
        },
        // formatter: (name) => {
        // var series = complainsResolvedChart.getOption().series[0];
        //     var value = series.data.filter(row => row.name === name)[0].value;
        //     return name + '    ' + value;
        // },
    },
    series: [
        {
            name: 'Complains Vs Resolved',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['35%', '50%'],
            avoidLabelOverlap: false,
            color: ['#0258FF', '#CCDEFF'],
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '22',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { value: 690, name: 'Complains' },
                { value: 1190, name: 'Resolved' },
            ]
        }
    ]
};

const mostComplainsOptions = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        // orient: 'vertical',
        // top: 20,
        bottom: 20,
        //left: 'middle',
        icon: 'circle',
    },
    series: [
        {
            name: 'Complained Against',
            type: 'pie',
            radius: '50%',
            color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00',],
            label: { "formatter": (params) => params.value },
            data: [
                { value: 90, name: 'ABC' },
                { value: 450, name: 'XYZ' },
                { value: 300, name: 'POQ' },
                { value: 221, name: 'MNO' },
                { value: 129, name: 'CDE' }
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};


const seriesLabel = {
    show: true,
    position: 'right',
};



export default function Dashboard() {

    const [role, setRole] = React.useState('');
    console.log(role,"roles dtaa");
    const [getNumbers, setGetNumbers] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState("Talent");
    const [notificationData, setNotificationData] = React.useState("");
console.log(notificationData,"notification data");

    const [mounth, setMounth] = React.useState({ Name: [], count: [] });
    const [status, setStatus] = React.useState('');
    const [status1, setStatus1] = React.useState({
        TimePeriod: 'Daily',
        Totalbookings: null,
        Usersdata: [],
        count: 0,
        isLoading: false,
        isError: false,
        error: ''
      });
    const [pending, setPending] = React.useState('');
    const [studioPending, setStudioPending] = React.useState('');
    const [talentPending, setTalentPending] = React.useState('');
    const [modlePending, setModlePending] = React.useState('');
    const [cutomerPending, setCustomerPending] = React.useState('');
    const [showFilters, setShowFilters] =  React.useState(false);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] =  React.useState(null);
    let navigate = useNavigate()


    const toggleFilters = () => {
        setShowFilters(prev => !prev);
    };


    const handleMenuItemSelect = (event) => {
        setSelectedItem(event.target.value);

    };
    const newData = getNumbers
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const newCounts = months.map(month => {
        const found = newData.find(item => item.month === month);
        return found ? found.count : 0;
    });



    const BookingChart = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: mounth.Name, // Use mounth.Name for xAxis data
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
            splitNumber: 10,
        },
        series: [
            {
                data: mounth.count, // Use mounth.count for series data
                label: {
                    show: true,
                    position: 'top'
                },
                type: 'line',
                smooth: true
            }
        ]
    };

    const BookingStatus = {

        color: ['#004B8D', '#FFBF00'],
        tooltip: {
            trigger: 'item',
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            top: '20',
            itemStyle: {
            },
            textStyle: {
                fontWeight: 700,
                fontFamily: 'Nunito',
            },
            icon: 'pin',

        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '22',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: status.completeBookings, name: 'Completed' },

                    { value: status.pendingBookings, name: 'Pending' },

                ]
            }
        ]
    };


    // const RegisterdNumber = {
    //     color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00'],
    //     legend: {
    //         data: ['Closed in < 7days', '7-14', '15-22', '23-30 days', 'Not Closed'],
    //         bottom: 0,
    //         icon: 'circle',
    //         formatter: (name) => { return name + '   ' },
    //     },
    //     grid: {
    //         left: 85
    //     },
    //     xAxis: {
    //         type: 'value',
    //         splitNumber: 2
    //     },
    //     yAxis: {
    //         type: 'category',
    //         inverse: true,
    //         data: ['Talents', 'Studio', 'Models'], // Assuming these are your categories
    //     },
    //     series: [
    //         {
    //             name: 'Closed in < 7days',
    //             type: 'bar',
    //             data: getNumbers.talents, // Replace with your actual data array for 'Closed in < 7days'
    //             label: seriesLabel,
    //             barGap: '35%',
    //             itemStyle: {
    //                 emphasis: {
    //                     barBorderRadius: [50, 50]
    //                 },
    //                 normal: {
    //                     barBorderRadius: [0, 6, 6, 0]
    //                 }
    //             }
    //         },
    //         {
    //             name: '7-14',
    //             type: 'bar',
    //             data: getNumbers.studio, // Replace with your actual data array for '7-14'
    //             label: seriesLabel,
    //             barGap: '35%',
    //             itemStyle: {
    //                 emphasis: {
    //                     barBorderRadius: [50, 50]
    //                 },
    //                 normal: {
    //                     barBorderRadius: [0, 6, 6, 0]
    //                 }
    //             }
    //         },
    //         {
    //             name: '15-22',
    //             type: 'bar',
    //             data: getNumbers.models, // Replace with your actual data array for '15-22'
    //             label: seriesLabel,
    //             barGap: '35%',
    //             itemStyle: {
    //                 emphasis: {
    //                     barBorderRadius: [50, 50]
    //                 },
    //                 normal: {
    //                     barBorderRadius: [0, 6, 6, 0]
    //                 }
    //             }
    //         }
    //     ]
    // };


    const Registerdata = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: months,
            boundaryGap: false
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: newCounts,
                label: {
                    show: true,
                    position: 'top'
                },
                type: 'line'
            }
        ]
    };
    React.useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/totalusersandtalents`)
            .then((res) => {

                setRole(res.data)
                // setTotal(res.data.total)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }
    React.useEffect(() => {
        GetNumber()
    }, [selectedItem])
    const [linedata, setLinedata] = React.useState([]);
const [totalBookings, setTotalBookings] = React.useState(0);
const [bookings, setBookings] = React.useState([]);


    const resetBooking = () =>{
        setShowFilters(false)
        setFromDate(null)
        setToDate(null)
        getBookings()
    }

    const getBookings = async () => {
        const fromDateFormatted = moment(fromDate).format("YYYY-MM-DD");
        const toDateFormatted = moment(toDate).format("YYYY-MM-DD");
      
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}followandlike/Getmonthlybookings?fromdate=${fromDateFormatted}&todate=${toDateFormatted}`
          );
      
          setLinedata(response.data.bookings);
          setTotalBookings(response.data.Total);
          setBookings(response.data.bookings);
        } catch (error) {
          console.error("Error fetching bookings:", error);
        }
      };

    const GetNumber = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}followandlike/gettalentsandstudio`, {
            params: { type: selectedItem }
        })
            .then((res) => {

                setGetNumbers(res.data.result)
                // setTotal(res.data.total)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }


    const handleTimePeriodChange = (value) => {
        setStatus1(prevStatus => ({
            ...prevStatus,
            TimePeriod: value,
            Totalbookings: null,
            Usersdata: [],
            count: 0
        }))
        // fetchBookingData()
    };



    React.useEffect(() => {
        getBookingChart()
    }, [])
    const getBookingChart = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + 'followandlike/allMonthdetails');
            const bookings = response.data.monthdata[0].Bookings;
            const mappedMonths = bookings.map((item) => ({
                Name: item.monthName,
                count: item.count
            }));


            // setMounth(mappedMonths);
            setMounth({
                Name: mappedMonths.map(item => item.Name), // Array of month names
                count: mappedMonths.map(item => item.count) // Array of counts
            });

            // Uncomment the following line if you need to set 'total' state
            // setTotal(response.data.total);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const OpenTalent = () => {
        Navigate("/talent")
    }


    React.useEffect(() => {
        GetBookingStatus()
    }, [])
    const GetBookingStatus = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + 'followandlike/getbookingandtalents');
            console.log(response.data, "booking status");
            setStatus(response.data)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    React.useEffect(() => {
        getNotification();
    }, []);

    const getNotification = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getthenotify`);
            const sortedNotifications = response.data.data.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            const unreadNotifications = sortedNotifications.filter(
                (notif) => !notif.opened !== false
            );
            setNotificationData(sortedNotifications);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };


    React.useEffect(() => {
        GetPending()
    }, [])
    const GetPending = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + 'followandlike/getalltotalandpending');
            let pendingModel = response.data.combinedResults.pendingmodel[0]?.total || "0";
            let pendingstudio = response.data.combinedResults.pendingstudio[0]?.total || "0";
            let pendingtalents = response.data.combinedResults.pendingtalents[0]?.total || "0";
            let pendingusers = response.data.combinedResults.pendingusers[0]?.total || "0";
            setStudioPending(pendingstudio)
            setModlePending(pendingModel)
            setTalentPending(pendingtalents)
            setCustomerPending(pendingusers)


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handlePendingTalent = () => {
        navigate('/talent', { state: true })
    }
    const handlePendingStudios = () => {
        navigate('/studio', { state: true })
    }
    const handlePendingModels = () => {
        navigate('/models', { state: true })
    }
    const handlePendingCustomer = () => {
        navigate('/customer', { state: true })
    }
    const formatDate = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const day = dateTime.getDate().toString().padStart(2, '0');
        const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
        const year = dateTime.getFullYear();
        const hours = dateTime.getHours().toString().padStart(2, '0');
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    return (
        <Box className='dashboard'>
            <MiniDrawer type={0} />
            <Box component="section" className="contentWraper">
                <Typography component='h4' variant='h4' className='page-heading' style={{ marginBottom: '50px', marginTop: "10px" }}>Dashboard</Typography>
                <Typography component='h4' variant='h4' className='page-heading' style={{ marginBottom: '50px', marginTop: "20px" }}></Typography>
               <Box className='p-20 '>
               <Grid container columnSpacing={4} rowSpacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Box className='whiteContainer mb-20' style={{padding: "40px 20px 30px 30px"}}>
                            <Grid container columnSpacing={2} rowSpacing={2} className='cardgrid'>
                                <Grid container spacing={2} className='cardgrid'>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Card className='card'>
                                            <CardContent style={{ backgroundColor: '#080706', color: 'white' }}>
                                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                        <Typography variant='h2'>{talentPending}/</Typography>
                                                        <Typography variant='subtitle2'>{role.talentsCount}</Typography>
                                                    </Box>
                                                    <img src={require('./../../../assets/images/verbal-complains.png')} alt='Talents' width='50' />
                                                </Box>
                                                <Typography variant='h6'>Talents</Typography>
                                            </CardContent>
                                            <Button style={{ color: 'black' }} endIcon={<ArrowForwardIcon />} onClick={handlePendingTalent}>View all</Button>

                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Card className='card'>
                                            <CardContent style={{ backgroundColor: '#080706', color: 'white' }}>
                                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                        <Typography variant='h2' style={{ marginRight: '8px' }}>{studioPending}/</Typography>
                                                        <Typography variant='subtitle2'>{role.studiosCount}</Typography>
                                                    </Box>
                                                    <img src={require('./../../../assets/images/companies.png')} alt='Studios' width='50' />
                                                </Box>
                                                <Typography variant='h6'>Studios</Typography>
                                            </CardContent>
                                            <Button style={{ color: 'black' }} endIcon={<ArrowForwardIcon />} onClick={handlePendingStudios}>View all </Button>

                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Card className='card'>
                                            <CardContent style={{ backgroundColor: '#080706', color: 'white' }}>
                                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                        <Typography variant='h2'>{modlePending}/</Typography>
                                                        <Typography variant='subtitle2'>{role.modelsCount}</Typography>
                                                    </Box>
                                                    <img src={require('./../../../assets/images/complains-vs-resolved.png')} alt='Models' width='50' />
                                                </Box>
                                                <Typography variant='h6'>Models</Typography>
                                            </CardContent>
                                            <Button style={{ color: 'black' }} endIcon={<ArrowForwardIcon />} onClick={handlePendingModels}>  View all  </Button>

                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Card className='card'>
                                            <CardContent style={{ backgroundColor: '#080706', color: 'white' }}>
                                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                                        <Typography variant='h2'>{cutomerPending}/</Typography>
                                                        <Typography variant='subtitle2'>{role.modelsCount}</Typography>
                                                    </Box>
                                                    <img src={require('./../../../assets/images/sexual-complains.png')} alt='Customer' width='50' />
                                                </Box>
                                                <Typography variant='h6'>Customers</Typography>
                                            </CardContent>
                                            <Button style={{ color: 'black' }} endIcon={<ArrowForwardIcon />} onClick={handlePendingCustomer}> View all   </Button>

                                        </Card>
                                    </Grid>
                                </Grid>




                            </Grid>
                        </Box>
                        <Grid container columnSpacing={3} rowSpacing={3} className='mb-20'>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box className='whiteContainer'>
                                    <Box component='div' className='chart' display="flex" justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <Typography component='h3' variant='' className='chart-heading'>Bookings</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant='h2' style={{ marginLeft: "20px" }}>{status.Totalbookings} </Typography>
                                            <Typography component='h6' className='card-title'>Total Bookings</Typography>
                                        </Box>
                                    </Box>
                                        <Grid container columnSpacing={3} rowSpacing={3} className='mb-20'>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Grid item xs={12} sm={6} md={8} lg={9}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <Button onClick={toggleFilters} style={{ display: 'flex', alignItems: 'center', color: 'black', fontWeight: 'bold' }}>
                                                            Filter
                                                        </Button>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Grid container spacing={2} style={{ alignItems: 'center' }}>
                                                            <Grid item xs={5} sm={4} md={5} lg={4}>
                                                                <Typography component="div" className="label"><Box fontWeight='bold' display='inline'>From Date</Box></Typography>
                                                                <FormControl variant="standard" fullWidth className="datepicker">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            label="from"
                                                                            value={fromDate}
                                                                            onChange={(newValue) => setFromDate(newValue)}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={5} sm={4} md={5} lg={4}>
                                                                <Typography component="div" className="label"><Box fontWeight='bold' display='inline'>To Date</Box></Typography>
                                                                <FormControl variant="standard" fullWidth className="datepicker">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            label="to"
                                                                            value={toDate}
                                                                            onChange={(newValue) => setToDate(newValue)}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '10px' }}>
                                                            {fromDate != null && toDate != null && (
                                                                <Button variant="contained" className="addbtn" onClick={getBookings} style={{ marginRight: '5px',backgroundColor:'black' }}>Apply</Button>
                                                            )}
                                                            <Button variant="contained" className="addbtn" onClick={resetBooking} style={{color:'white', backgroundColor:'black'}}>Reset</Button>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    <ReactEcharts option={BookingChart} className='echarts' />
                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Grid container columnSpacing={3} rowSpacing={3} className='mb-20'> */}
                        {/* <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Box className='whiteContainer'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains Type</Typography>
                                        <ReactEcharts option={complainsTypeOptions} className='echarts' />
                                    </Box>
                                </Box>
                            </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box className='whiteContainer'>
                                <Box component='div' className='chart'>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography component='h3' className='chart-heading' style={{ fontWeight: 'bold' }}>Registered</Typography>
                                        <Select
                                            value={selectedItem}
                                            onChange={handleMenuItemSelect}
                                            displayEmpty
                                            className='dropdown-menu'
                                            variant="outlined"
                                            style={{ minWidth: '150px' }}
                                        >
                                            {/* <MenuItem value='' disabled>Select Menu Item</MenuItem> */}
                                            <MenuItem value='Talent'>Talents</MenuItem>
                                            <MenuItem value='Studio'>Studios</MenuItem>
                                            <MenuItem value='Customer'>Customers</MenuItem>
                                            <MenuItem value='Model'>Models</MenuItem>
                                        </Select>
                                    </Box>
                                    <ReactEcharts option={Registerdata} className='echarts' />
                                </Box>
                            </Box>
                        </Grid>

                        {/* </Grid> */}
                        {/* <Grid container columnSpacing={3} rowSpacing={3} >
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Box className='whiteContainer'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains Resolved</Typography>
                                        <ReactEcharts option={CustomerChart} className='echarts' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Box className='whiteContainer'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Most Complain Companies</Typography>
                                        <ReactEcharts option={mostComplainsOptions} className='echarts' />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid> */}

                        {/* <Box className='whiteContainer mt-20'>
                            <Box component='div' className='chart closingcomplains'>
                                <Typography component='h3' variant='' className='chart-heading'>Days to close complains</Typography>
                                <ReactEcharts option={RegisterdNumber} className='echarts' />
                            </Box>
                        </Box> */}

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Box className='whiteContainer'>
                            <Box component='div' className='activity'>
                                <Typography component='h3' variant='' className='recent-activity-heading'>Recent Activity
                                </Typography>
                                <Box component='div' className='recent-activity-container'>
                                    {notificationData && notificationData.length > 0 ? (
                                        notificationData.map((ndata, i) => (
                                            <Card key={i} style={{ marginBottom: 8, borderLeft: !ndata.opened==true? '4px solid green' : '4px solid transparent', borderRadius: 8 }}>
                                                <CardContent>
                                                    <Typography variant="subtitle1" className="fz-15 semibold" gutterBottom>

                                                        {ndata.title}
                                                    </Typography>
                                                    <img src={require('./../../../assets/images/complains-vs-resolved.png')} alt='Models' width='50' />


                                                    <Typography variant="body2" className="fz-15" color="textSecondary">
                                                        {ndata.body}
                                                    </Typography>
                                                    <Typography variant="body2" className="fz-15" color="textSecondary">
                                                        {formatDate(ndata.createdAt)}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        ))
                                    ) : (""
                                        // <Typography variant="body1">No Notifications</Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
               </Box>
            </Box>
        </Box>

    )
}
