import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Input, Tabs, Tab, Avatar, IconButton, CardMedia, } from '@mui/material'
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import MiniDrawer from '../../../components/drawer'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios, { all } from 'axios'
import moment from "moment";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import DOMPurify from 'dompurify';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ImageIcon from '@mui/icons-material/Image';
import { useSnackbar, SnackbarProvider } from 'notistack'
import VideocamIcon from '@mui/icons-material/Videocam';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { OutlinedInput } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ListItemText } from '@mui/material';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// import 'swiper/swiper-bundle.css';
// import 'swiper/swiper-bundle.min.css';


import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MmsIcon from '@mui/icons-material/Mms';
import Check from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// -----------------------------------------------------------------------------------------------------------
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#3554D1',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#3554D1',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#3554D1',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#3554D1',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#3554D1',
        // backgroundColor:
        //     theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#545454',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#3554D1',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <DashboardIcon />,
        2: <MmsIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


export default function AddTalent() {

    // letz admindetails = JSON.parse(localStorage.getItem("userData"))
    // let navigate = useNavigate()
    let location = useLocation()
    console.log(location.state, "locationsssssssssssssssss");

    // -------------------service page---------------------------------------


    const [allDetails, setAllDetails] = React.useState('');
    console.log(allDetails,'allDetailsallDetails');
    const [details, setDetails] = React.useState('Photo');
    const [calenderData, setCalenderData] = React.useState([]);
    console.log(calenderData, "calenderdataa");
    const [images, setImages] = React.useState({})
    const [videos, setVideos] = React.useState({})
    const [images1, setImages1] = React.useState({})
    console.log(images1, "imagesdata");
    console.log(videos, "videodata");


    const localizer = momentLocalizer(moment);

    // ----------------------------------------------------------------------


    React.useEffect(() => {
        getTalentDetailes();
        Mediadata();
    }, [])

    const getTalentDetailes = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `talents/getthetalentsbyidwithcat/${location.state._id}`)
            .then((res) => {
                console.log(res.data, "talentdetailes detailsss");
                setAllDetails(res.data.userDetails
                )
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }


    // const handleChangeDetails = (event, newValue) => {
    //     setDetails(newValue);

    // };


    const Mediadata = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/gettsmmedia/${location.state._id}`);
            const mediaData = response.data.media.media;

            const flatMediaArray = mediaData.flat();
            const processedImages = flatMediaArray
                .filter(item => item.mimetype.includes('image'))
                .map(item => item.path);

            const processedVideos = flatMediaArray
                .filter(item => item.mimetype.includes('video'))
                .map(item => item.path);

            setImages1(processedImages);
            setVideos(processedVideos);

        } catch (error) {
            console.error('Error fetching media data:', error);
        }
    };

    React.useEffect(() => {
        getPhotoes();
        getVideos();
    }, [])
    const getPhotoes = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getphotosbyid/${location.state._id}`)
            .then((res) => {
                setImages(res.data.allimage)
            })
            .catch((error) => {
            })
    }
    const getVideos = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getvideosbyid/${location.state._id}`)
            .then((res) => {

                setVideos(res.data.allvideos)

            })
            .catch((error) => {

            })
    }
    const sanitizedContent = DOMPurify.sanitize(allDetails.description, { ALLOWED_TAGS: ['img'] });

    React.useEffect(() => {
        getBookings()
    }, [])

    const getBookings = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `paymentsdata/getthebookings`)
            .then((res) => {
                // console.log(res.data)
                // Filter the bookings where the email matches location.state.email
                // const filteredBookings = res.data.data.filter(booking => booking.email === location?.state.email);
                
                // Set the filtered bookings data to the state
                setCalenderData(res.data.data);
    
            })
            .catch((error) => {
                console.log(error, "error in booking");
            })
    }
    


console.log(calenderData,"calenderDatacalenderDatacalenderData")
    //--------------------------------------------------------------------------------------------------
    return (
      <Box className="Addcompany">
        <MiniDrawer type={6} />
        <Box component="section" className="contentWraper">
          <Box className=" whiteContainer">
            <Box className="content p-20">
              <Typography component="h6" className="formTitle">
                View Talent
              </Typography>

              <Grid
                style={{ marginBottom: 15 }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="carosuel mb-10">
                                    <Avatar > <img width={'100%'} height={35} src={process.env.REACT_APP_BASE_URL + `${allDetails && allDetails.profile}`} /></Avatar>
                                </div>
                            </Grid> */}

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="carousel mb-10">
                    <Avatar
                      src={
                        process.env.REACT_APP_BASE_URL +
                        `${allDetails && allDetails.profile}`
                      }
                      alt="Profile Picture"
                      sx={{
                        width: "10%",
                        height: "10%",
                        objectFit: "cover",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          `${allDetails && allDetails.profile}`
                        }
                        alt="Profile"
                        style={{
                          width: "10%",
                          height: "10%",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    </Avatar>
                  </div>
                </Grid>
                <Box className="mb-10">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box className="d-flex align-items-center">
                        <Box>
                          <Typography
                            variant="body1"
                            className="fz-14 semibold"
                          >
                            Talent Name{" "}
                          </Typography>
                          <Typography variant="body1" className="fz-14">{`${
                            allDetails.name ? allDetails.name : ""
                          }${
                            allDetails.name && allDetails.lastname ? " " : ""
                          }${
                            allDetails.lastname ? allDetails.lastname : ""
                          }`}</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="fz-15 semibold">
                        Category{" "}
                      </Typography>
                      <Typography variant="body1" className="fz-15">
                        {allDetails && allDetails.Category_id?.length
                          ? allDetails.Category_id.map(
                              (data) => data.title
                            ).join(", ")
                          : "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="fz-14 semibold">
                        Followers{" "}
                      </Typography>
                      <Typography variant="body1" className="fz-14">
                        {allDetails.followers
                          ? allDetails.followers.length
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="fz-14 semibold">
                        Rating{" "}
                      </Typography>
                      <Typography variant="body1" className="fz-14">
                        {allDetails.reviews ? allDetails.reviews : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="fz-14 semibold">
                        Services{" "}
                      </Typography>
                      <Typography variant="body1" className="fz-14">
                        {allDetails.serviceCount
                          ? allDetails.serviceCount
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body1" className="fz-14 semibold">
                        Location{" "}
                      </Typography>
                      <Typography variant="body1" className="fz-14">
                        {allDetails.location ? allDetails.location : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={1}
                  className=" mb-10 align-items-center"
                >
                  <Box
                    className="flex-box mb-10 segmentControl"
                    style={{ justifyContent: "start", width: "100%" }}
                  >
                    <Tabs
                      value={details}
                      onChange={(event, newValue) => setDetails(newValue)}
                      textColor="secondary"
                      indicatorColor="secondary"
                      aria-label="secondary tabs example"
                      className="segmentControl"
                      sx={{
                        "& .MuiTabs-indicator": { display: "none" },
                        width: "100%",
                      }}
                    >
                      <Tab
                        className="subsegment"
                        sx={{ textTransform: "none", width: "80%" }}
                        value="Photo"
                        icon={<ImageIcon />}
                        label="Images"
                      />
                      <Tab
                        className="subsegment"
                        sx={{ textTransform: "none", width: "100%" }}
                        value="Video"
                        icon={<VideocamIcon />}
                        label="Videos"
                      />
                      <Tab
                        className="subsegment"
                        sx={{ textTransform: "none", width: "100%" }}
                        value="calendar"
                        icon={<CalendarMonthIcon />}
                        label="Bookings"
                      />
                    </Tabs>
                  </Box>
                  {details === "Photo" && (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {images1 && images1.length > 0 ? (
    <Grid container spacing={2}> {/* Creates a grid container */}
        {images1.map((imagePath, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}> {/* Responsive grid item */}
                <Card>
                    <CardMedia
                        component="img"
                        image={process.env.REACT_APP_BASE_URL + imagePath}
                        alt={`img ${i}`}
                        style={{ width: '100%', height: '250px' }}
                    />
                </Card>
            </Grid>
        ))}
    </Grid>
) : (
    <Typography>No images available</Typography>
)}
                    </div>
                  )}

                  

                  {details === "Video" && (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {videos.length > 0 ? (
                        videos.map((videoPath, i) => (
                          <Card
                            key={i}
                            className="m-2"
                            style={{ flex: "0 0 calc(33.33% - 1rem)" }}
                          >
                            <video controls width="100%" height='250px'>
                              <source
                                src={process.env.REACT_APP_BASE_URL + videoPath}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </Card>
                        ))
                      ) : (
                        <Typography>No videos available</Typography>
                      )}
                    </div>
                  )}

                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {details === "calendar" && (
                      <Grid container spacing={3}>
                        {calenderData &&
                          calenderData.map(
                            (selectedEvent, index) => (
                              (
                                <Grid item xs={12} md={6} key={index}>
                                  <Card
                                    style={{
                                      height: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <IconButton
                                      style={{ alignSelf: "flex-end" }}
                                    >
                                      {selectedEvent.status &&
                                      selectedEvent.status === "complete" ? (
                                        <CheckCircleIcon
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <CheckCircleIcon
                                          style={{ color: "brown" }}
                                        />
                                      )}
                                    </IconButton>
                                    <CardContent style={{ flexGrow: 1 }}>
                                      <Typography variant="body2" component="p">
                                        <strong>Title:</strong>{" "}
                                        {selectedEvent.title}
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        <strong>Name:</strong>{" "}
                                        {selectedEvent.userName}
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        <strong>Start:</strong>{" "}
                                        {moment(selectedEvent.start).format(
                                          "MMM D YYYY, h:mm a"
                                        )}
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        <strong>End:</strong>{" "}
                                        {moment(selectedEvent.end).format(
                                          "MMM D YYYY, h:mm a"
                                        )}
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        <strong>Description:</strong>{" "}
                                        {selectedEvent.description}{" "}
                                        {/* 'description' should be lowercase */}
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        <strong>Email:</strong>{" "}
                                        {selectedEvent.email}
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        <strong>Location:</strong>{" "}
                                        {selectedEvent.location}
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        <strong>Status:</strong>{" "}
                                        {selectedEvent.status}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )
                            )
                          )}
                      </Grid>
                    )}
                  </div>
                </Grid>

                {/* <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                            {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                : <Typography className='p18_tag' >There is no description</Typography>} */}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    );
}