// src/firebase/messaging.js
import { getMessaging, onMessage, isSupported } from 'firebase/messaging';
import app from './firebaseConfig';

export const setupMessaging = async () => {
  try {
    const supported = await isSupported();
    if (supported) {
      const messaging = getMessaging(app);
      // Listen for foreground messages
      onMessage(messaging, (payload) => {
        console.log(payload, 'Foreground Message in messaging.js');
        const notificationTitle = payload.data.title;
        const notificationOptions = {
          body: payload.data.body,
          icon: "/Noti.jpg",
        };

        // Check and request notification permission
        if ('Notification' in window) {
          if (Notification.permission === 'granted') {
            new Notification(notificationTitle, notificationOptions);
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                new Notification(notificationTitle, notificationOptions);
              }
            });
          }
        }
      });
    } else {
      console.warn("Firebase Messaging is not supported in this browser.");
    }
  } catch (error) {
    console.error("Error setting up messaging:", error);
  }
};
