import { PlaylistAddRounded, SearchOutlined } from '@mui/icons-material'
import { Box, Button, Typography, ClickAwayListener, FormControl, Menu, Grow, InputAdornment, Avatar, MenuItem, MenuList, Popper, TextField, Grid, Tabs, Tab } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios'
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar, SnackbarProvider } from 'notistack'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Checkbox from '@mui/material/Checkbox';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const localizer = momentLocalizer(moment);

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
export default function Models() {
    let navigate = useNavigate()

    const AddModels = () => {
        navigate('/addmodels');
    }

    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("all");
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [modelDetails, setModelDetails] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [viewId, setViewId] = React.useState('');
    const [viewOpen, setViewOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [photoId, setPhotoId] = React.useState('');
    const [isPrimary, setIsPrimary] = React.useState(false)

    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };
    const label = { inputProps: { 'aria-label': 'Switch demo' } };


    const handleRemove = () => {
        setStatus("all")
        setSearch('')
    }

    const [anchorE2, setAnchorE2] = React.useState(null);
    const [allDetails, setAllDetails] = React.useState('');
    const [calenderOpen, setCalenderOpen] = React.useState(false);
    const [followersOpen, setFollowersOpen] = React.useState(false);

    const [images, setImages] = React.useState({})
    const [videos, setVideos] = React.useState({})
    console.log(images, "imagesdata");
    console.log(videos, "videodata");
    const [details, setDetails] = React.useState('Photo');

    const open1 = Boolean(anchorE2);
    const OpenMenu = (event, data) => {
        setAllDetails(data)
        setAnchorE2(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorE2(null);
    };
    const [calenderData, setCalenderData] = React.useState([]);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    React.useEffect(() => {
        getBookings()
    }, [])

    const getBookings = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `paymentsdata/getthebookings`)
            .then((res) => {
                setCalenderData(res.data.data)

            })
            .catch((error) => {
                console.log(error, "error");
            })
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const handleCancelClick = () => {
        setSearch('');

    };

    React.useEffect(() => {
        getModelDetails()
    }, [rowsPerPage, page, search, status])

    const getModelDetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `adminmodels/getallmodels?page=${page}&size=${rowsPerPage}&search=${search}&isStatus=${status == 'all' ? '' : status}`)
            .then((res) => {
                console.log(res.data, "res.datares.data");
                setModelDetails(res.data.getdata)
                setTotal(res.data.totalcount)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(modelDetails);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;
        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }
        setModelDetails(fromvar);

        const body = {
            index_position: currElIndexNumber
        };

        axios.put(process.env.REACT_APP_BASE_URL + `adminmodels/updatemodelposition/${final._id}`, body)
            .then((res) => {
                console.log(res.data, "drag response");
                getModelDetails();
            })
            .catch((err) => console.log(err.response, "drag & drop"));
    };


    // -------------------------action---------------------------------------------------------------------------------------

    const handleClickOpen = () => {
        setAnchorEl(null);
        setAnchorE2(null);
        setDeleteId(allDetails._id)
        setOpen(true);
    };
    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const EditDetails = () => {
        setAnchorE2(null);
        navigate('/editmodels', { state: allDetails })
    }

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }
    const handleView = () => {
        navigate('/viewmodels', { state: allDetails })
        // setAnchorE2(null);
        // setAlldata(allDetails);
        // setPhotoId(allDetails._id)
        // setOpenview(true)

    }


    const DeleteModal = () => {
        axios.put(process.env.REACT_APP_BASE_URL + `adminmodels/deletemodels/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Model deleted successfully", { variant: 'success' })
                getModelDetails()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const handlePrimary = (e, data) => {
        setIsPrimary(e.target.checked)
        let body = {
            isPrimary: isPrimary
        }
        axios.put(process.env.REACT_APP_BASE_URL + `adminmodels/updateprimarydata/${data._id}`, body)
            .then((res) => {
                console.log(res.data, "is primeryyy");
                getModelDetails();
            })
            .catch((err) => console.log(err.response, "error"));

    }


    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
    };
    const events = calenderData.map((caldata) => ({
        id: caldata._id,
        title: caldata.Bookingname,
        userName: caldata.serviceID ? caldata.serviceID.name : "-",
        start: new Date(caldata.start?.dateTime),
        end: new Date(caldata.end?.dateTime),
        email: caldata.email,
        Description: caldata.description,
        summary: caldata.summary,
        location: caldata.location,


    }));
    const handleChangeDetails = (event, newValue) => {
        setDetails(newValue);

    };

    const handleChangeVisible = async (e, data) => {
        var body = {
            isStatus: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `adminmodels/updateservicedata/${data._id}`, body)
            .then((res) => {
                console.log(res, "resssss");
                if (res.data.message === "Status Enabled") {
                    enqueueSnackbar(res.data.message, { variant: 'success' })
                }
                else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                getModelDetails();
            }).catch((err => {
                console.log("error while shop enable");
            }))
    }

    const handleChangeCheck = async (e, data) => {
        var body = {
            isService: e.target.checked,
        }
        await axios.put(process.env.REACT_APP_BASE_URL + `adminmodels/updatemodulesdata/${data._id}`, body)
            .then((res) => {
                getModelDetails();
            }).catch((err => {
                enqueueSnackbar(err.response.data.error, { variant: 'error' })
                console.log(err.response.data.error, "error while shop enable");
            }))
    }

    const viewBookings = (row) => {
        navigate('/viewbooking')
    }
    const closeCalender = () => {
        setCalenderOpen(false)
    }
    const viewPosts = () => {

    }
    const viewReviews = () => {
    }
    const viewFollowers = () => {
        setFollowersOpen(true)
    }
    const handleFollowersClose = () => {
        setFollowersOpen(false)
    }
    React.useEffect(() => {
        getPhotoes();
        getVideos();
    }, [photoId])
    const getPhotoes = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getphotosbyid/${photoId}`)
            .then((res) => {
                setImages(res.data.allimage)
            })
            .catch((error) => {
            })
    }

   
    const getVideos = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getvideosbyid/${photoId}`)
            .then((res) => {

                setVideos(res.data.allvideos)

            })
            .catch((error) => {

            })
    }

    // -----------------------------------------------------------------------------------------------------------------------
    const sanitizedContent = DOMPurify.sanitize(alldata.description, { ALLOWED_TAGS: ['img'] });
    return (
        <Box className='companies'>
            <MiniDrawer type={7} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='page-heading' >Models</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => AddModels()} style={{ marginTop: '20px', backgroundColor:'black' }}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Model</Button>
                </Box>
                <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10 }} alignItems="flex-end">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                onChange={handleSearchChange}
                                placeholder="Search Here..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlinedIcon className='fz-18' />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search && (
                                                <IconButton onClick={handleCancelClick}>
                                                    <CancelIcon className='fz-18' />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <Typography component='div' className='label'><Box fontWeight='bold' display='inline'>Filter</Box></Typography>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                variant='outlined'
                                className='select'
                            >
                                <MenuItem value={'all'} className='menuitems'><Box fontWeight='bold' display='inline'>All</Box></MenuItem>
                                <MenuItem value={true} className='menuitems'><Box fontWeight='bold' display='inline'>Approved</Box></MenuItem>
                                <MenuItem value={false} className='menuitems'><Box fontWeight='bold' display='inline'>Rejected</Box></MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {status !== 'all' &&
                        <Grid item xs={12} sm={3} md={2} lg={2}>
                            <Button onClick={handleRemove} style={{ backgroundColor: 'black', color: 'white', marginTop: '8px' }}>Reset</Button>
                        </Grid>
                    }
                </Grid>

                <Box className='whiteContainer'>
                    <Box>
                        <TableContainer component={Paper} id='table'>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                                <TableHead className='DT-head'>
                                    <TableRow className=''>

                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Image</TableCell>
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Model name</TableCell>
                                        {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Category</TableCell> */}
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Status</TableCell>
                                        {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 10, maxWidth: 10, padding: '6px 16px', fontWeight:'bold' }}>Primary</TableCell> */}
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Followers</TableCell>
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Bookings</TableCell>
                                        <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 50, padding: '6px 16px', fontWeight:'bold' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <DragDropContext onDragEnd={handleOnDragEnd} >
                                    <Droppable droppableId="characters">
                                        {(provided) => (

                                            <TableBody className="characters " ref={provided.innerRef}>
                                                {modelDetails && modelDetails?.length > 0 ? modelDetails.map((row, i) => (
                                                    <Draggable key={row._id} draggableId={row._id} index={i} >
                                                        {(provided) => (
                                                            <>
                                                                <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className=''>
                                                                    <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                                                                        {row.profile?.length > 0 ? (
                                                                            <img
                                                                                src={process.env.REACT_APP_BASE_URL + `${row.profile}`}
                                                                                height={50}
                                                                                width={50}
                                                                                style={{ borderRadius: '50%' }}
                                                                            />
                                                                        ) : (
                                                                            <Avatar
                                                                                sx={{ width: 32, height: 32, margin: 1 }}
                                                                                alt={row.name ? row.name.charAt(0).toUpperCase() : 'Y'} // Alt attribute for accessibility
                                                                            >
                                                                                {row.name ? row.name.charAt(0).toUpperCase() : 'Y'}
                                                                            </Avatar>
                                                                        )}
                                                                    </TableCell>

                                                                    <TableCell className='DT-row' align="left" style={{ minWidth: 30 }}>{`${row.name ? row.name : ''}${row.name && row.lastname ? ' ' : ''}${row.lastname ? row.lastname : ''}`}</TableCell >

                                                                    {/* <TableCell className='DT-row' align="left" style={{ minWidth: 30 }}> {row && row.Category_id?.length ? row.Category_id.map((data) => data.category_name).join(', ') : "-"}</TableCell > */}

                                                                    <TableCell className='DT-row' align="left" style={{ minWidth: 50 }}>
                                                                        <FormControlLabel className='m-0' control={<IOSSwitch onChange={(e) => handleChangeVisible(e, row)} checked={row.isStatus} sx={{ m: 1 }} />} />
                                                                    </TableCell>

                                                                    {/* <TableCell align="center" component="th" scope="row" padding="none" className='DT-row'  >
                                                                        <Checkbox onChange={(e) => handleChangeCheck(e, row)} checked={row.isService} style={{ color: 'black' }}/>
                                                                    </TableCell> */}


                                                                    <TableCell className='DT-row' align="left" style={{ minWidth: 30, cursor: 'pointer' }} onClick={() => viewFollowers(row)}>{row && row.Followers && row.Followers.length > 0 ? row.following.length : "-"}</TableCell >
                                                                   
                                                                    <TableCell className='DT-row' align="left" style={{ minWidth: 50, cursor: 'pointer' }} onClick={(e) => viewBookings()} >{calenderData.length}</TableCell >
                                                                    <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                                        <Button
                                                                            aria-controls={open1 ? 'basic-menu' : undefined}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={open1 ? 'true' : undefined}
                                                                            onClick={(e) => OpenMenu(e, row)}
                                                                        >
                                                                            <MoreVertIcon sx={{ color: '#000' }} />
                                                                        </Button>
                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={anchorE2}
                                                                            open={open1}
                                                                            onClose={closeMenu}
                                                                            MenuListProps={{
                                                                                'aria-labelledby': 'basic-button',
                                                                            }}
                                                                        >
                                                                            <MenuItem onClick={() => EditDetails(row)}><ModeEditIcon className='icons text-grey' /> Edit</MenuItem>
                                                                            <MenuItem onClick={(e) => handleClickOpen(row)}><DeleteIcon className='icons text-grey' /> Delete</MenuItem>
                                                                            <MenuItem onClick={(e) => handleView(row)}><RemoveRedEyeIcon className='icons text-grey' /> View</MenuItem>
                                                                        </Menu>


                                                                    </TableCell>



                                                                </TableRow>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                )) : <TableRow className=''>
                                                    <TableCell className='DT-row' align="center" colSpan={5}>No Data Found</TableCell >
                                                </TableRow>}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className='DT-pagination'
                        />
                        <Dialog open={open} keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            className='custom_modal'>
                            <DialogTitle>Would you like to delete Models?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleClose} className='cancel_btn' style={{ color: '#FFFFFF', backgroundColor:"black" }}>No</Button>
                                <Button onClick={DeleteModal} className='theme-btn' style={{ color: '#FFFFFF', backgroundColor: '#000' }}>Yes</Button>
                            </DialogActions>
                        </Dialog>


                        {/* ----------------------------------------------------------------------------------------------------------- */}

                        <BootstrapDialog
                            onClose={handleClickClose}
                            aria-labelledby="customized-dialog-title"
                            open={openview}
                            className='custom_modal'
                            maxWidth='md'
                        >
                            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                Model Details
                            </DialogTitle>

                            <DialogContent dividers>
                                <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>

                                    <Box className='mb-10 d-flex align-items-start justify-content-between'>
                                        <Box className=''>
                                            <Typography component='grid' variant='body1' className='fz-14 semibold'>Modlename: </Typography>
                                            <Typography component='grid' variant='body1' className='fz-14 '>{alldata.name ? alldata.name : "-"}</Typography>
                                        </Box>
                                        <Box className=''>
                                            <Typography component='grid' variant='body1' className='fz-14 semibold'>Username: </Typography>
                                            <Typography component='grid' variant='body1' className='fz-14 '>{alldata.username ? alldata.username : "-"}</Typography>
                                        </Box>

                                        <Box className=''>
                                            <Typography component='span' variant='body1' className='fz-15 semibold'>Category: </Typography>
                                            <Typography component='span' variant='body1' className='fz-15 '>{alldata && alldata.Category_id?.length && alldata.Category_id.map((data) => data.category_name).join(', ')}</Typography>

                                        </Box>

                                        <Box className=''>
                                            <Typography component='grid' variant='body1' className='fz-14 semibold'>Height: </Typography>
                                            <Typography component='grid' variant='body1' className='fz-14 '>{alldata.Height ? alldata.Height : "-"}</Typography>
                                        </Box>
                                        <Box className=''>
                                            <Typography component='grid' variant='body1' className='fz-14 semibold'>Weight: </Typography>
                                            <Typography component='grid' variant='body1' className='fz-14 '>{alldata.weight ? alldata.weight : "-"}</Typography>
                                        </Box>
                                        <Box className=''>
                                            <Typography component='grid' variant='body1' className='fz-14 semibold'>Body Type: </Typography>
                                            <Typography component='grid' variant='body1' className='fz-14 '>{alldata.body_type ? alldata.body_type : "-"}</Typography>
                                        </Box>
                                        <Box className=''>
                                            <Typography component='grid' variant='body1' className='fz-14 semibold'>Color: </Typography>
                                            <Typography component='grid' variant='body1' className='fz-14 '>{alldata.skin_color ? alldata.skin_color : "-"}</Typography>
                                        </Box>
                                        <Box className=''>
                                            <Typography component='grid' variant='body1' className='fz-14 semibold'>Location: </Typography>
                                            <Typography component='grid' variant='body1' className='fz-14 '>{alldata.location ? alldata.location : "-"}</Typography>
                                        </Box>



                                    </Box>


                                </Grid>

                                <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="carosuel mb-10">

                                            <Typography component='span' variant='body1' className='fz-15 semibold'>Primary Image: </Typography>
                                            <Swiper
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper "
                                                slidesPerView={1}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >
                                                <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${alldata && alldata.profile}`} /></SwiperSlide>

                                            </Swiper>
                                        </div>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="carosuel mb-10">

                                            <Typography component='span' variant='body1' className='fz-15 semibold'>Coverimage: </Typography>
                                            <Swiper
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper "
                                                slidesPerView={1}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >

                                                <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${alldata.converimage}`} /></SwiperSlide>
                                            </Swiper>
                                        </div>

                                    </Grid>
                                    <Box className='flex-box mb-10 segmentControl' style={{ justifyContent: 'start', width: '100%' }}>
                                        <Tabs
                                            value={details}
                                            onChange={handleChangeDetails}
                                            textColor="secondary"
                                            indicatorColor="secondary"
                                            aria-label="secondary tabs example"
                                            className='segmentControl'
                                            sx={{ '& .MuiTabs-indicator': { display: 'none' }, width: '100%' }}
                                        >
                                            <Tab className='subsegment'sx={{ textTransform: "none", width: '100%' }}value="Photo"icon={<ImageIcon />}/>
                                            <Tab className='subsegment'sx={{ textTransform: "none", width: '100%' }}value="Video" icon={<VideocamIcon />} />
                                        </Tabs>
                                    </Box>
                                    {details === 'Photo' && (
    <>
        {images && images.length > 0 ? (
            images.map((imageData, i) =>
                imageData.images && imageData.images.length > 0 ? (
                    <Card key={i} className="m-2" style={{ flex: '0 0 calc(50% - 1rem)' }}>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            className="mySwiper"
                            slidesPerView={1}
                            autoplay={{ delay: 2000 }} // Adjust delay as needed (in milliseconds)
                            pagination={{ clickable: true }}
                        >
                            {imageData.images.map((tdata, j) => (
                                <SwiperSlide key={j}>
                                    <img
                                        width="100%"
                                        height={200}
                                        src={`${process.env.REACT_APP_BASE_URL}${tdata}`}
                                        alt={`Image ${j + 1}`}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Card>
                ) : (
                    ''
                )
            )
        ) : (
            ''
        )}
    </>
)}
                                    {details === "Video" && (
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            <div className="d-flex flex-wrap">
                                                {videos && videos.length > 0 ? (
                                                    videos.map((videoObj, index) => (
                                                        <div key={index} style={{ flex: '0 0 calc(33.33% - 1rem)', margin: '0.5rem' }}>
                                                            {videoObj.videos && (
                                                                <div className="card" style={{ width: '100%', height: '100%', position: 'relative' }}>
                                                                    <video controls style={{ width: '100%', height: 'auto' }} src={`${process.env.REACT_APP_BASE_URL}${videoObj.videos}`} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Box>
                                    )}




                                </Grid>

                                <Typography component='div' variant='body1' className='fz-14 semibold'>Description: </Typography>
                                {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                    : <Typography className='p18_tag' >There is no description</Typography>}



                            </DialogContent>
                            <DialogActions>
                                <Button className='cancel_btn' style={{ color: '#B4522D' }} onClick={handleClickClose}>Close</Button>
                            </DialogActions>
                        </BootstrapDialog>




                    </Box>
                </Box>
            </Box>

            <div style={{ marginLeft: '400px', marginTop: '100px' }}>
                <Modal
                    isOpen={calenderOpen}
                    onRequestClose={closeCalender}
                    contentLabel="View Bookings Modal"
                >
                    <div style={{ display: 'grid', gridTemplateColumns: '70% 40%', gap: '20px', marginLeft: '200px', marginTop: '100px' }}>
                        <div className="calendar-container">
                            <div className="calendar">
                                <Button onClick={closeCalender} sx={{ '&:hover': { border: '1px solid white' } }}>
                                    <ArrowBackIcon size={40} />
                                </Button>
                                <Calendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    onSelectEvent={handleSelectEvent}
                                    style={{ height: 500 }}
                                />
                            </div>
                        </div>
                        {selectedEvent && (
                            <div className="event-details">
                                <h2>Event Details</h2>
                                <p><strong>Title:</strong> {selectedEvent.title}</p>
                                <p><strong>Name:</strong> {selectedEvent.userName}</p>
                                <p><strong>Start:</strong> {selectedEvent.start.toString()}</p>
                                <p><strong>End:</strong> {selectedEvent.end.toString()}</p>
                                <p><strong>Description:</strong> {selectedEvent.Description}</p>
                                <p><strong>Email:</strong> {selectedEvent.email}</p>
                                <p><strong>Location:</strong> {selectedEvent.location}</p>
                            </div>
                        )}
                    </div>

                    <button onClick={closeCalender}>Close</button>
                </Modal>
            </div>



            {/* -------------------------------------------------------------------------------------------- */}
            <Dialog open={followersOpen} keepMounted
                onClose={handleFollowersClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'>
                <DialogTitle style={{fontWeight:'500'}}>Followers Details</DialogTitle>
                <DialogContent dividers>
                    <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">
                        {alldata && alldata.following?.length > 0 && alldata.following.map((data) => (
                            <Card sx={{ minWidth: 275, margin: 2, border: '1px solid #ccc', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom> </Typography>
                                    <Box className=''>
                                        <Avatar src={process.env.REACT_APP_BASE_URL + `${data.profile}`} sx={{ width: 80, height: 80, marginRight: 1 }} />
                                        <Typography component='span' variant='body1' className='fz-15 '>{data.username && data.username}</Typography>
                                    </Box>
                                    <Box className='' sx={{ marginLeft: 'auto' }}>
                                        <Typography component='span' variant='body1' className='fz-15 '>{data.date && data.date}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' style={{ color: '#000', fontWeight:'900' }} onClick={handleFollowersClose}>Close</Button>
                </DialogActions>

            </Dialog>
        </Box>
    )
}