import { BusinessOutlined, Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
// Import modular messaging functions and isSupported
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
// import { initializeApp } from 'firebase/app';
// import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import axios from 'axios'
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useSnackbar, SnackbarProvider } from 'notistack'
import { useCookies } from 'react-cookie';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import 'firebase/compat/database';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCZZtiypfAxh2fXDosLXK9Z92a_EJujX4s",
  authDomain: "chatapp-rn-79e4e.firebaseapp.com",
  databaseURL: "https://chatapp-rn-79e4e-default-rtdb.firebaseio.com",
  projectId: "chatapp-rn-79e4e",
  storageBucket: "chatapp-rn-79e4e.appspot.com",
  messagingSenderId: "512330630070",
  appId: "1:512330630070:web:7e12f53ca543f2142a7717",
  measurementId: "G-VHLEHZY7BJ"
};


export default function Login() {
  // const app = initializeApp(firebaseConfig);
  // const auth = getAuth(app);
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  // For authentication using compat:
  // import 'firebase/compat/auth'; (if not already imported)
  const auth = firebase.auth();
  let navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const { enqueueSnackbar } = useSnackbar()
  // const messaging = getMessaging();
  // Initialize Firebase Messaging inside useEffect and check for support
  React.useEffect(() => {
    (async () => {
      try {
        const supported = await isSupported();
        if (supported) {
          // Pass the initialized Firebase app to getMessaging
          const messaging = getMessaging(firebase.app());
          const currentToken = await getToken(messaging, {
            vapidKey: "BCL7SMn0_Qyg6KE_HDwWMEJd1nUE3bvbOpGq4eB_EsH0H50Q2IduHfOhFyggVp4TFBgwNtvRnWonu7IEk9EM8dA"
          });
          if (currentToken) {
            setFcmKey(currentToken);
          } else {
            console.log('No FCM token available.');
          }
        } else {
          console.warn("Firebase Messaging is not supported in this browser.");
        }
      } catch (error) {
        console.error(error, 'Error getting FCM token:');
      }
    })();
  }, []);
  const [emailerror, setEmailerror] = React.useState({email:""})
  const [passworderror, setPassworderror] = React.useState({password:""})
  const [buttonview, setButtonview] = React.useState(false)
  const [fcmkey, setFcmKey] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false);

  // React.useEffect(() => {
  //   getToken(messaging, { vapidKey: "BCL7SMn0_Qyg6KE_HDwWMEJd1nUE3bvbOpGq4eB_EsH0H50Q2IduHfOhFyggVp4TFBgwNtvRnWonu7IEk9EM8dA" })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         setFcmKey(currentToken)
  //       } else {
  //         console.log('No FCM token available.');
  //       }
  //     }).catch((error) => {
  //       console.error(error, 'Error getting FCM token:');
  //     });
  // }, [])

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const Validation = () => {
    // Check if email is empty
    if (!email) {
      setEmailerror({email: "Email field is required"});
      return false;
    }
    
    // Regex for validating email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    
    // Check if email format is invalid
    if (!emailRegex.test(email)) {
      setEmailerror({email: "Invalid email format"});
      return false;
    }
  
    // Check if password is empty
    if (!password) {
      setPassworderror({password: "Password field is required"});
      return false;
    }
  
    return true;
  }
  

const _handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    handleSubmit(e);
  }
};


const handleSubmit = async () => {
  let Valid = Validation();
  if (Valid) {
    setButtonview(true);  // Show loading

    let body = {
      email: email,
      password: password,
      // fcmkey: fcmkey,
      roleusers: true,  
      role: 'Admin'     
    };
    try {
      const res = await axios.post(process.env.REACT_APP_BASE_URL + `talents/adminandadminusers`, body);
      console.log(res)
      if (res.data.adminUser.role_name) {
        console.log("Admin User Login");
        localStorage.setItem("userData", JSON.stringify(res.data.adminUser));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("fcmkey", fcmkey);
        localStorage.setItem('permission', res.data.adminUser.role_name);
      } else {
        console.log("Normal User Login");

        localStorage.setItem("userData", JSON.stringify(res.data.adminUser));
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("fcmkey", fcmkey);
      }

      setCookie('value', res.data.token);

      firebase.database().ref(`Users/${res.data.adminUser._id}`).update({
        fcm: fcmkey,
        isOnline: true
      });

      navigate('/dashboard');
      enqueueSnackbar("Login successful", { variant: 'success' });

    } catch (error) {
      setButtonview(false);

      enqueueSnackbar(
        error.message === 'Network Error'
          ? error.message
          : error.response?.data?.message
            ? error.response.data.message
            : "Something went wrong.",
        { variant: 'error' }
      );
      console.log(error, "error");
    }
  }
};


  return (
    <Box className='login'>
      <Grid container spacing={0}>
        {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: 'linear-gradient(to right, #366253, #B4522D, #8E211D, #131313)' }}> */}
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ background: '#000' }}>
          <Box className='login_img'>
            <Box className='logintext_box'>
              <h1 className='heading'>Empower Your Cultural Network!</h1>
              <Typography component='p' variant='body1' className='subheading'>Manage talents, models, and studios seamlessly. Your hub for streamlined operations and creative control!</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}  >
          <Box className='Login_content' >
            <Typography component='h4' variant='h4'>Admin Login</Typography>
            <Box className='form'>

              <FormControl variant="standard">
                <Typography component='div' className='label'>Email <Typography component='span' className='star'>*</Typography></Typography>
                <TextField
                  id="filled-size-small"
                  variant="outlined"
                  size="small"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailerror({email:""})
                  }}
                  className='textfield'
                  placeholder='Email'
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                  }}
                />
              {emailerror.email && <p style={{ color: 'red' }} className="fz-13 m-0" >{emailerror.email}</p>}
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <Typography component='div' className='label'>Password <Typography component='span' className='star'>*</Typography></Typography>
                <TextField
                  id="filled-size-small"
                  variant="outlined"
                  size="small"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setPassworderror({password:""})}}
                  className='textfield'
                  placeholder='Password'
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {passworderror.password && <p style={{ color: 'red' }} className="fz-13 m-0" >{passworderror.password}</p>}
              </FormControl>
              

            </Box>
            {buttonview == false ?
              <Button className='loginbtn'  onKeyDown={_handleKeyDown}  onClick={handleSubmit}>Login</Button>
              :
              <Button className='loginbtn'>Please Wait...</Button>}
            <Box className='links-container'>
              {/* <Box className='b-link'  >
                <Typography component='a' href='/userlogin'>Login as User</Typography>
              </Box> */}
              <Box className='a-link'>
                <Typography component='a' href='/forgotpassword' style={{color:'black'}}>Forgot your password?</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>

  )
}
